export const COOKIE_TEXT_TEMPLATE = {
	headerHtml: '<p>We value your privacy. When you provide us with your personal data, including preferences, we will only process information that is necessary for the purpose for which it has been collected. You can read about the other ways we may use your data in our </p>',
	footerHtml: '<p class="cassie-cookie-modal--footer-content" id="cassie_cookie_footer_content">By using our website, you accept the terms of our <u><a href="https://trustcassie.com/privacy-policy/" target="_blank">privacy policy.</a></u></p>',
	preBanner: {
		preBannerText: 'We use cookies on our website. Some are necessary for our site to function, while others are optional but help improve your experience.',
		acceptAllButtonText: 'Accept All',
		rejectAllButtonText: 'Reject All',
		cookieSettingsButtonText: 'Cookie Settings'
	},
	gpcBanner: {
		gpcBannerTitle: 'GPC Detected',
		gpcBannerText: 'We have detected a Global Privacy Control (GPC) signal, please confirm if you wish to accept all cookies including or excluding those flagged as GPC.',
		acceptAllButtonText: 'Accept All',
		acceptAllExcGpcButtonText: 'Accept All Excluding GPC',
		backButtonText: 'Back'
	},
	banner: {
		submitPreferencesButtonText: 'Submit Preferences',
		userConsentTabLabelText: 'User Consent',
		legitimateInterestTabLabelText: 'Legitimate Interests',
		acceptAllButtonText: 'Accept All',
		rejectAllButtonText: 'Reject All',
		optInLabel: 'Opt In',
		optOutLabel: 'Opt Out'
	}
}
export const COOKIE_COLLECTION_TEMPLATE = {
	cookieCategories: [
		{
			name: 'Strictly Necessary',
			isStrictlyNecessary: true,
			selected: true,
			isOptIn: false,
			description:
			// eslint-disable-next-line max-len
				'These cookies are necessary for website functions such as setting your privacy preferences, logging in or completing forms. By using this site, you agree we may place these cookies on your device.',
			cookies: [
				{
					name: 'Site Features',
					isOptIn: false,
					cookieScriptType: 'Strictly Necessary',
					description:
						'These are the minimum necessary cookies we require for the functionality of our site.'
				},
				{
					name: 'Cassie Cookie',
					cookieScriptType: 'Strictly Necessary',
					description:
						'Cassie enables us to capture users preferences compliantly under regulatory frameworks.'
				}
			]
		},
		{
			name: 'Tracking Cookies',
			isStrictlyNecessary: false,
			isOptIn: false,
			selected: false,
			description:
			'We use tracking cookies to improve our understanding of users visiting our website.',
			cookies: [
				{
					name: 'Tracking Pixel',
					cookieScriptType: 'Legitimate Interest',
					isOptIn: false,
					description:
						'We use tracking pixels to analyze anonymous website traffic data, which helps us optimize the site and show you more relevant content and offers.'
				},
				{
					name: 'Cassie Beacon',
					cookieScriptType: 'User Consent',
					description:
						'Cassie enables us to capture users preferences compliantly under regulatory frameworks.'
				}
			]
		}
	]
}
