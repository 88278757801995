<template>
	<ValidationForm
		#default="{ handleSubmit }"
		tag="div"
		class="cassie-vertical-md"
	>
		<CookieTemplateLayout
			:header-title="'Configure Cookie Appearance Template' | useLabels('Configure Cookie Appearance Template')"
			:header-caption="'Configure an appearance template' | useLabels('Configure a collection of cookie categories and scripts')"
		>
			<template #content>
				<Message
					v-if="cookieAppearanceTemplate.isSystem && !cloneMode"
					type="warning"
				>
					You cannot edit system default Cookie Appearance Templates. Please ensure they meet your business needs before use or clone this and edit accordingly.
				</Message>
				<SectionCard>
					<template #title>
						Details
					</template>
					<template #body>
						<v-row dense>
							<v-col cols="6">
								<TextField
									v-model="cookieAppearanceTemplate.name"
									label="Name *"
									:rules="{required: true, max: 50}"
									:disabled="!userCanCreateUpdate || (cookieAppearanceTemplate.isSystem && !cloneMode)"
								/>
							</v-col>
							<v-col cols="6">
								<Dropdown
									v-model="cookieAppearanceTemplate.brandId"
									:disabled="isEdit || cloneMode || !userCanCreateUpdate || !clientMultiBranded || singleBrand || (cookieAppearanceTemplate.isSystem && !cloneMode)"
									:items="brandItems"
									custom-sort
									label="Brand *"
									:rules="{ required: clientMultiBranded}"
								/>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col cols="12">
								<Textarea
									v-model="cookieAppearanceTemplate.description"
									label="Description *"
									no-resize
									:rules="{required: true, max: 500}"
									:disabled="!userCanCreateUpdate || (cookieAppearanceTemplate.isSystem && !cloneMode)"
								/>
							</v-col>
						</v-row>
					</template>
				</SectionCard>
				<SectionCard>
					<template #title>
						General Settings
					</template>
					<template #body>
						<v-row dense>
							<v-col cols="3">
								<Toggle
									:value.sync="cookieAppearanceTemplate.forceBannerInteraction"
									label="Force Banner Interaction"
									:disabled="!userCanCreateUpdate || (cookieAppearanceTemplate.isSystem && !cloneMode)"
									tooltip-text="If enabled, the user will be forced to interact with the banner before they can continue to use the site."
								/>
							</v-col>
							<v-col cols="3">
								<Toggle
									:value.sync="iabFrameworkValue"
									label="IAB Banner"
									:disabled="!userCanCreateUpdate || (cookieAppearanceTemplate.isSystem && !cloneMode)"
									tooltip-text="Enabling IAB will use the standard IAB banner appearance settings."
									@update:value="toggleFeaturesIAB('iabFrameworkValue', $event)"
								/>
							</v-col>
							<v-col cols="3">
								<Dropdown
									v-model="cookieAppearanceTemplate.cookieDropBehaviourType"
									:items="COOKIES_DROPPED_ON_LOAD_ITEMS"
									label="Cookies Dropped on Page Load *"
									:tooltip-text="cookieDropTooltipText"
									rules="required"
									:disabled="!userCanCreateUpdate || (cookieAppearanceTemplate.isSystem && !cloneMode)"
								/>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="3">
								<Toggle
									:value.sync="cookieAppearanceTemplate.loadTemplateHtml"
									label="Load Template HTML"
									:disabled="!userCanCreateUpdate || (cookieAppearanceTemplate.isSystem && !cloneMode)"
								/>
							</v-col>
							<v-col cols="3">
								<Toggle
									:value.sync="cookieAppearanceTemplate.loadTemplateJs"
									label="Load Template JavaScript"
									:disabled="!userCanCreateUpdate || (cookieAppearanceTemplate.isSystem && !cloneMode)"
								/>
							</v-col>
							<v-col cols="3">
								<Toggle
									:value.sync="cookieAppearanceTemplate.loadTemplateCss"
									label="Load Template CSS"
									:disabled="!userCanCreateUpdate || (cookieAppearanceTemplate.isSystem && !cloneMode)"
								/>
							</v-col>
						</v-row>
					</template>
				</SectionCard>
				<PreBannerAppearanceCard
					:pre-banner.sync="cookieAppearanceTemplate.preBanner"
					:edit-or-clone-mode="isEdit || cloneMode"
					:user-can-create-update="userCanCreateUpdate && (!cookieAppearanceTemplate.isSystem || cloneMode)"
					:iab-is-enabled="iabFrameworkValue"
				/>
				<GpcBannerAppearanceCard
					:gpc-banner.sync="cookieAppearanceTemplate.gpcBanner"
					:edit-or-clone-mode="isEdit || cloneMode"
					:user-can-create-update="userCanCreateUpdate && (!cookieAppearanceTemplate.isSystem || cloneMode)"
					:iab-is-enabled="iabFrameworkValue"
				/>
				<SectionCard>
					<template #title>
						Edit Cookie Selection Button
					</template>
					<template #title-action>
						<Toggle
							:value.sync="cookieAppearanceTemplate.showCookieSelectionButton"
							:disabled="!userCanCreateUpdate || (cookieAppearanceTemplate.isSystem && !cloneMode)"
							label-left
							label="Enabled"
						/>
					</template>
					<template #subtitle>
						Your Cookie Banner allows users to customise their consent and see what scripts will be loaded into their browser.
					</template>
				</SectionCard>
				<BannerAppearanceCard
					:banner.sync="cookieAppearanceTemplate.banner"
					:show-pre-banner="cookieAppearanceTemplate.preBanner.preBannerEnabled"
					:is-edit="isEdit"
					:cookie-appearance-template-id="cookieAppearanceTemplate.id"
					:edit-or-clone-mode="isEdit || cloneMode"
					:user-can-create-update="userCanCreateUpdate && (!cookieAppearanceTemplate.isSystem || cloneMode)"
					:iab-is-enabled="iabFrameworkValue"
				/>
				<SectionCard v-if="!iabFrameworkValue">
					<template #title>
						Preview
					</template>
					<template #body>
						<CookieBannerPreview
							:cookie-appearance-template="cookieAppearanceTemplate"
							:cookie-text-template="COOKIE_TEXT_TEMPLATE"
							:cookie-collection-template="COOKIE_COLLECTION_TEMPLATE"
						/>
					</template>
				</SectionCard>
			</template>
			<template #footer>
				<PageActionRow>
					<template #actions>
						<SecondaryActionButton @click="backToOverview">
							{{ backToOverviewText }}
						</SecondaryActionButton>
						<v-spacer />
						<PrimaryActionButton
							v-if="userCanCreateUpdate && (!cookieAppearanceTemplate.isSystem || cloneMode)"
							@click="handleSubmit(submit)"
						>
							{{ isEdit ? 'Save' : 'Create' }}
						</PrimaryActionButton>
					</template>
				</PageActionRow>
			</template>
		</CookieTemplateLayout>
	</ValidationForm>
</template>

<script>
import { mapGetters } from 'vuex'
import CookieTemplateLayout from '../cookie-template-layout.vue'
import ValidationForm from '../../../../../../../shared/components/validation-form.vue'
import SectionCard from '../../../../../../../shared/components/section-card.vue'
import TextField from '../../../../../../../shared/components/text-field.vue'
import Dropdown from '../../../../../../../shared/components/dropdown.vue'
import Textarea from '../../../../../../../shared/components/textarea.vue'
import Toggle from '../../../../../../../shared/components/toggle.vue'
import PreBannerAppearanceCard from './pre-banner-appearance-card.vue'
import GpcBannerAppearanceCard from './gpc-banner-appearance-card.vue'
import PrimaryActionButton from '../../../../../../../shared/components/primary-action-button.vue'
import SecondaryActionButton from '../../../../../../../shared/components/secondary-action-button.vue'
import BannerAppearanceCard from './banner-appearance-card.vue'
import CookieBannerPreview from '../cookie-banner-preview/cookie-banner-preview.vue'
import PageActionRow from '../../../../../../../shared/components/page-action-row.vue'
import { singleBrand, defaultBrandInSelect, clientMultiBranded, brandOptions, UNIVERSAL_BRAND, UNIVERSAL_BRAND_ITEM } from '../../../../../../../shared/state/brands.js'
import { showSnackbar } from '../../../../../../../shared/state/snackbar.js'
import { COOKIE_APPEARANCE_TEMPLATES } from '../../../../../router/route-names.js'
import { postCookieAppearanceTemplate, putCookieAppearanceTemplate } from '../../../../../../../shared/utils/api/cookies/cookie-appearance-templates.js'
import { COOKIE_TEXT_TEMPLATE, COOKIE_COLLECTION_TEMPLATE } from './cookie-banner-preview-default-values.js'
import { CAN_CREATE_UPDATE_COOKIE_APPEARANCE_TEMPLATES } from '../../../../../../../shared/permissions/admin-portal-permissions.js'
import {
	PRE_BANNER_LOCATIONS,
	COLOUR_SCHEME_IDENTIFIERS,
	PRE_BANNER_DARK_COLOUR_SCHEME,
	PRE_BANNER_LIGHT_COLOUR_SCHEME,
	GPC_BANNER_DARK_COLOUR_SCHEME,
	GPC_BANNER_LIGHT_COLOUR_SCHEME,
	BANNER_DARK_COLOUR_SCHEME,
	BANNER_LIGHT_COLOUR_SCHEME,
	BANNER_LOCATIONS,
	COOKIES_DROPPED_ON_LOAD_ITEMS,
	COOKIES_DROPPED_ON_LOAD
} from './appearance-enums.js'
import Message from '../../../../../../../shared/components/message.vue'

export default {
	components: {
		CookieTemplateLayout,
		ValidationForm,
		SectionCard,
		TextField,
		Dropdown,
		Textarea,
		Toggle,
		PreBannerAppearanceCard,
		GpcBannerAppearanceCard,
		BannerAppearanceCard,
		CookieBannerPreview,
		PageActionRow,
		PrimaryActionButton,
		SecondaryActionButton,
		Message
	},
	props: {
		cookieAppearanceTemplateToEdit: Object,
		accessedVia: String,
		cloneMode: {
			type: Boolean,
			default: false
		}
	},
	setup () {
		return {
			brandOptions,
			showSnackbar,
			COOKIE_TEXT_TEMPLATE,
			COOKIE_COLLECTION_TEMPLATE,
			COOKIES_DROPPED_ON_LOAD_ITEMS,
			COOKIES_DROPPED_ON_LOAD,
			clientMultiBranded,
			singleBrand,
			defaultBrandInSelect
		}
	},
	data () {
		const ACCESSED_VIA_MANAGE_COOKIE_BANNER = 'manage-cookie-banner'
		const ACCESSED_VIA_COOKIE_APPEARANCE_TEMPLATES = 'cookie-appearance-templates'
		const cookieAppearanceTemplate = JSON.parse(JSON.stringify(this.cookieAppearanceTemplateToEdit || {
			name: null,
			description: null,
			brandId: this.defaultBrandInSelect,
			showCookieSelectionButton: true,
			forceBannerInteraction: false,
			cookieDropBehaviourType: null,
			loadTemplateHtml: true,
			loadTemplateCss: true,
			loadTemplateJs: true,
			iabFramework: 0,
			preBanner: {
				preBannerEnabled: true,
				preBannerLocation: PRE_BANNER_LOCATIONS.BOTTOM,
				showPreBannerAcceptAllButton: true,
				showPreBannerRejectAllButton: true,
				showPreBannerCookieSettingsButton: true,
				preBannerColourSchemeIdentifier: COLOUR_SCHEME_IDENTIFIERS.DARK,
				preBannerColourSchemeJSON: PRE_BANNER_DARK_COLOUR_SCHEME
			},
			gpcBanner: {
				gpcBannerEnabled: true,
				gpcBannerLocation: PRE_BANNER_LOCATIONS.BOTTOM,
				showGpcBannerAcceptAllButton: true,
				showGpcBannerAcceptAllExcGpcButton: true,
				showGpcBackButton: true,
				gpcBannerColourSchemeIdentifier: COLOUR_SCHEME_IDENTIFIERS.DARK,
				gpcBannerColourSchemeJSON: GPC_BANNER_DARK_COLOUR_SCHEME
			},
			banner: {
				autoShowModal: false,
				displayLogo: false,
				imageUrl: null,
				bannerLocation: BANNER_LOCATIONS.LEFT,
				showToggleLabels: true,
				showBannerAcceptAllButton: true,
				showBannerRejectAllButton: true,
				bannerColourSchemeIdentifier: COLOUR_SCHEME_IDENTIFIERS.DARK,
				bannerColourSchemeJSON: BANNER_DARK_COLOUR_SCHEME
			}
		}))
		if (this.cookieAppearanceTemplateToEdit) {
			if (!cookieAppearanceTemplate.preBanner.preBannerColourSchemeJSON) {
				cookieAppearanceTemplate.preBanner.preBannerColourSchemeIdentifier = COLOUR_SCHEME_IDENTIFIERS.NONE
				cookieAppearanceTemplate.preBanner.preBannerColourSchemeJSON = PRE_BANNER_LIGHT_COLOUR_SCHEME
			}
			if (!cookieAppearanceTemplate.gpcBanner.gpcBannerColourSchemeJSON) {
				cookieAppearanceTemplate.gpcBanner.gpcBannerColourSchemeIdentifier = COLOUR_SCHEME_IDENTIFIERS.NONE
				cookieAppearanceTemplate.gpcBanner.gpcBannerColourSchemeJSON = GPC_BANNER_LIGHT_COLOUR_SCHEME
			}
			if (!cookieAppearanceTemplate.banner.bannerColourSchemeJSON) {
				cookieAppearanceTemplate.banner.bannerColourSchemeIdentifier = COLOUR_SCHEME_IDENTIFIERS.NONE
				cookieAppearanceTemplate.banner.bannerColourSchemeJSON = BANNER_LIGHT_COLOUR_SCHEME
			}
		}
		return {
			cookieAppearanceTemplate,
			ACCESSED_VIA_MANAGE_COOKIE_BANNER,
			ACCESSED_VIA_COOKIE_APPEARANCE_TEMPLATES
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		userCanCreateUpdate () {
			return this.productAreaPermission(CAN_CREATE_UPDATE_COOKIE_APPEARANCE_TEMPLATES)
		},
		iabEnabled () {
			return this.iabFrameworkValue === 1
		},
		iabFrameworkValue: {
			get () {
				return this.cookieAppearanceTemplate.iabFramework === 1
			},
			set (value) {
				this.cookieAppearanceTemplate.iabFramework = value ? 1 : 0
			}
		},
		isEdit () {
			return !!this.cookieAppearanceTemplateToEdit && !this.cloneMode
		},
		backToOverviewText () {
			if (this.accessedVia === this.ACCESSED_VIA_MANAGE_COOKIE_BANNER) return 'Back to Cookie Banner'
			else return 'Back to Overview'
		},
		brandItems () {
			// ensures when viewing universal object it displays in dropdown
			const containsUniversal = brandOptions.value.map(brand => {
				return brand.value
			}).includes(UNIVERSAL_BRAND)

			if (containsUniversal || !this.isEdit) {
				return brandOptions.value
			} else {
				return [
					UNIVERSAL_BRAND_ITEM,
					...brandOptions.value
				]
			}
		},
		cookieDropTooltipText () {
			if (this.cookieAppearanceTemplate.cookieDropBehaviourType === COOKIES_DROPPED_ON_LOAD.DROP_ALL) {
				return `'Drop All Cookies' should only be selected if the Cookie Banner is exclusively used outside of the GDPR region`
			} else if (this.cookieAppearanceTemplate.cookieDropBehaviourType === COOKIES_DROPPED_ON_LOAD.DROP_STRICTLY_NECESSARY) {
				return `'Drop Strictly Necessary Cookies Only' will drop any Cookie classed as 'Strictly Necessary' on page load`
			} else if (this.cookieAppearanceTemplate.cookieDropBehaviourType === COOKIES_DROPPED_ON_LOAD.DROP_NONE) {
				return `'No Cookies Dropped' will not drop any Cookies in to the end users browser until they have made some form of selection on the Cookie Banner.`
			} else if (this.cookieAppearanceTemplate.cookieDropBehaviourType === COOKIES_DROPPED_ON_LOAD.DROP_ALL_EXC_GPC) {
				return `'Drop All Cookies Excluding GPC' will drop all Cookies except those classed as 'GPC' on page load`
			} else {
				return null
			}
		}
	},
	created () {
		if (this.accessedVia === this.ACCESSED_VIA_MANAGE_COOKIE_BANNER) {
			this.scrollToTop()
		}
	},
	methods: {
		scrollToTop () {
			if (this.accessedVia === this.ACCESSED_VIA_MANAGE_COOKIE_BANNER) {
				document.querySelector('#manage_cookie_banner_container').scrollTo(0, 0)
			}
		},
		backToOverview () {
			this.scrollToTop()
			if (this.accessedVia === this.ACCESSED_VIA_MANAGE_COOKIE_BANNER) {
				this.$emit('close')
			} else {
				this.$router.push({ name: COOKIE_APPEARANCE_TEMPLATES })
			}
		},
		toggleFeaturesIAB (arg, eventValue) {
			if (eventValue) {
				this.cookieAppearanceTemplate.gpcBanner.gpcBannerEnabled = false
				this.cookieAppearanceTemplate.preBannerEnabled = false
				this.cookieAppearanceTemplate.banner.showBannerRejectAllButton = false
				this.cookieAppearanceTemplate.banner.showBannerAcceptAllButton = true
				this.cookieAppearanceTemplate.preBanner.showPreBannerRejectAllButton = false
			}
		},
		async submit () {
			const appearanceTemplate = JSON.parse(JSON.stringify(this.cookieAppearanceTemplate))
			if (appearanceTemplate.banner.displayLogo && appearanceTemplate.banner.imageUrl == null) {
				this.showSnackbar({ color: 'red', text: 'Please upload a logo or disable the "Display Logo" option' })
				return
			}
			const hasPreBannerColourScheme = appearanceTemplate.preBanner.preBannerColourSchemeIdentifier !== COLOUR_SCHEME_IDENTIFIERS.NONE
			const hasGpcBannerColourScheme = appearanceTemplate.gpcBanner.gpcBannerColourSchemeIdentifier !== COLOUR_SCHEME_IDENTIFIERS.NONE
			const hasBannerColourScheme = appearanceTemplate.banner.bannerColourSchemeIdentifier !== COLOUR_SCHEME_IDENTIFIERS.NONE
			delete appearanceTemplate.preBanner.preBannerColourSchemeIdentifier
			delete appearanceTemplate.gpcBanner.gpcBannerColourSchemeIdentifier
			delete appearanceTemplate.banner.bannerColourSchemeIdentifier
			delete appearanceTemplate.createdDate
			delete appearanceTemplate.brandName
			delete appearanceTemplate.createdBy
			delete appearanceTemplate.lastEditiedBy
			delete appearanceTemplate.lastEditedDate
			delete appearanceTemplate.usage
			appearanceTemplate.preBanner.preBannerColourSchemeJSON = hasPreBannerColourScheme ? JSON.stringify(appearanceTemplate.preBanner.preBannerColourSchemeJSON) : null
			appearanceTemplate.gpcBanner.gpcBannerColourSchemeJSON = hasGpcBannerColourScheme ? JSON.stringify(appearanceTemplate.gpcBanner.gpcBannerColourSchemeJSON) : null
			appearanceTemplate.banner.bannerColourSchemeJSON = hasBannerColourScheme ? JSON.stringify(appearanceTemplate.banner.bannerColourSchemeJSON) : null
			if (!appearanceTemplate.banner.displayLogo) appearanceTemplate.banner.imageUrl = null
			if (!this.isEdit) {
				delete appearanceTemplate.id
				await postCookieAppearanceTemplate(appearanceTemplate).then(response => {
					this.$emit('submit')
					this.$emit('get-new-appearance-template-id', response.data.newCookieAppearanceTemplateId)
				})
				this.showSnackbar('Cookie appearance template created')
			} else {
				await putCookieAppearanceTemplate(appearanceTemplate)
				this.$emit('submit')
				this.showSnackbar('Cookie appearance template updated')
			}
			this.backToOverview()
		}
	}
}
</script>
