<template>
	<div
		class="d-flex"
		:style="{
			'--slider-color': $attrs['slider-color'],
			'--slider-background-on': $attrs['slider-background-on'],
			'--slider-background-off': $attrs['slider-background-off'],
		}"
		@click.stop.prevent="handleClick"
	>
		<span class="switch-text">
			<slot />
		</span>
		<div
			class="switch"
			role="switch"
			aria-checked="false"
			bis_skin_checked="1"
			v-on="$listeners"
		>
			<input
				type="checkbox"
				class="switch-checkbox"
			>
			<span
				aria-hidden="true"
				:class="{ 'switch-slider--active': isActive }"
				class="switch-slider "
			/>
		</div>
	</div>
</template>

<script>
export default {
	name: 'base-switch',
	prop: ['value'],
	data () {
		return {
			isActive: Boolean(this.value)
		}
	},
	watch: {
		$attrs (attrs) {
			if (!attrs.value && attrs.value === this.isActive) return
			this.isActive = attrs.value
		}
	},
	methods: {
		handleClick () {
			this.isActive = !this.isActive
			this.$emit('input', this.isActive)
		}
	}
}
</script>

<style lang="scss" scoped>
.switch {
  &-text {
    font-size: 12px;
    cursor: pointer;
  }

  position: relative;
  float: right;
  width: 50px;
  height: 24px;
  margin-left: 0.3rem;
  cursor: pointer;
  &-slider {
    position: absolute;
    cursor: pointer;
    background-color: var(--slider-background-off);
    border-color: var(--slider-background-off);
    border-style: solid;
    border-width: 1px;
    border-radius: 34px;
    border-image: initial;
    inset: 0 7px 0 0;
  }
  &-slider--active {
    background-color: var(--slider-background-on);
    border-color: var(--slider-background-on);
  }
  &-slider::before {
    position: absolute;
    bottom: 1px;
    left: 0;
    width: 20px;
    height: 20px;
    content: '';
    background-color: var(--slider-color);
    border-color: var(--slider-background-off);
    border-style: solid;
    border-width: 1px;
    border-radius: 50%;
    border-image: initial;
    transition: all 0.4s ease 0s;
  }
  &-slider--active::before {
    left: 21px;
    border-color: var(--slider-background-on);
  }
  &-checkbox {
    position: relative;
    right: 48px;
    bottom: 27px;
    z-index: 1;
    display: inline-block;
    width: 90px;
    width: 16px;
    min-width: 16px;
    height: 24px;
    height: 16px;
    margin: -3px 10px 0 0;
    clear: none;
    line-height: 0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #bbb;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
    -moz-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
    opacity: 0;
    -webkit-transition: 0.05s border-color ease-in-out;
    -moz-transition: 0.05s border-color ease-in-out;
    -ms-transition: 0.05s border-color ease-in-out;
    -o-transition: 0.05s border-color ease-in-out;
    transition: 0.05s border-color ease-in-out;
    -webkit-appearance: none;
  }
}
</style>
