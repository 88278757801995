<template>
	<SectionCard>
		<template #title>
			GPC Banner
		</template>
		<template #title-action>
			<Toggle
				:value.sync="gpcBanner.gpcBannerEnabled"
				:disabled="!userCanCreateUpdate || iabIsEnabled"
				label-left
				label="Enabled"
			/>
		</template>
		<template #subtitle>
			If the browser is transmitting a Global Privacy Control (GPC) signal, this banner will be displayed.
		</template>
		<template
			v-if="gpcBanner.gpcBannerEnabled"
			#body
		>
			<v-row>
				<v-col cols="5">
					<!-- add permission -->
					<RadioGroup
						radio-group-label="GPC Banner Location *"
						:radio-group-value.sync="gpcBanner.gpcBannerLocation"
						:radios="PRE_BANNER_LOCATION_RADIOS"
						image-radio-group
						:disabled="!userCanCreateUpdate"
						@update:radioGroupValue="updateGpcBanner('gpcBannerLocation', $event)"
					/>
				</v-col>
				<v-col cols="7">
					<div class="cassie-vertical-md">
						<RadioGroup
							radio-group-label="Colour Scheme *"
							:radio-group-value.sync="gpcBanner.gpcBannerColourSchemeIdentifier"
							:radios="COLOUR_SCHEME_RADIOS"
							:disabled="!userCanCreateUpdate"
							tooltip-text="When 'None' is selected, styling will be applied from the page in which the embed code is placed and will not be under the control of Cassie, the Light scheme will be used for the preview."
							@update:radioGroupValue="selectColourScheme($event)"
						/>
						<ColourPicker
							v-if="isCustomScheme && customColourSchemeButtons"
							:elements.sync="customColourSchemeButtons"
							:disabled="!userCanCreateUpdate"
							@update-element-changed="updateColourChanged"
						/>
					</div>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="3">
					<Toggle
						:value.sync="gpcBanner.showGpcBannerAcceptAllButton"
						label="Show Accept All Button"
						:disabled="!userCanCreateUpdate"
						@update:value="updateGpcBanner('showGpcBannerAcceptAllButton', $event)"
					/>
				</v-col>
				<v-col cols="3">
					<Toggle
						:value.sync="gpcBanner.showGpcBannerAcceptAllExcGpcButton"
						label="Show Accept All Exc GPC Button"
						:disabled="!userCanCreateUpdate"
						@update:value="updateGpcBanner('showGpcBannerAcceptAllExcGpcButton', $event)"
					/>
				</v-col>
				<v-col cols="3">
					<Toggle
						:value.sync="gpcBanner.showGpcBackButton"
						label="Show Back Button"
						:disabled="!userCanCreateUpdate"
						@update:value="updateGpcBanner('showGpcBackButton', $event)"
					/>
				</v-col>
			</v-row>
		</template>
	</SectionCard>
</template>

<script>
import SectionCard from '../../../../../../../shared/components/section-card.vue'
import RadioGroup from '../../../../../../../shared/components/radio-group.vue'
import ColourPicker from '../../../../../../../shared/components/colour-picker.vue'
import Toggle from '../../../../../../../shared/components/toggle.vue'
import {
	PRE_BANNER_LOCATION_RADIOS,
	COLOUR_SCHEME_RADIOS,
	COLOUR_SCHEME_IDENTIFIERS,
	GPC_BANNER_CUSTOM_SCHEME_COLOURS,
	GPC_BANNER_DARK_COLOUR_SCHEME,
	GPC_BANNER_LIGHT_COLOUR_SCHEME,
	GPC_BANNER_DEFAULT_CUSTOM_COLOUR_SCHEME
} from './appearance-enums.js'

export default {
	components: {
		SectionCard,
		RadioGroup,
		Toggle,
		ColourPicker
	},
	props: {
		gpcBanner: Object,
		editOrCloneMode: {
			type: Boolean,
			default: false
		},
		userCanCreateUpdate: Boolean,
		iabIsEnabled: Boolean
	},
	setup () {
		return {
			PRE_BANNER_LOCATION_RADIOS,
			COLOUR_SCHEME_RADIOS,
			COLOUR_SCHEME_IDENTIFIERS
		}
	},
	data () {
		return {
			customColourSchemeButtons: GPC_BANNER_CUSTOM_SCHEME_COLOURS
		}
	},
	computed: {
		isCustomScheme () {
			return this.gpcBanner.gpcBannerColourSchemeIdentifier === COLOUR_SCHEME_IDENTIFIERS.CUSTOM
		}
	},
	created () {
		if (this.editOrCloneMode && this.isCustomScheme) this.setCustomColours()
	},
	methods: {
		setCustomColours () {
			const colourScheme = JSON.parse(JSON.stringify(GPC_BANNER_CUSTOM_SCHEME_COLOURS))

			for (const key in this.gpcBanner.gpcBannerColourSchemeJSON) {
				const matchingElement = colourScheme.find(({ id }) => id === key)
				if (matchingElement) matchingElement.color = this.gpcBanner.gpcBannerColourSchemeJSON[key]
			}
			this.customColourSchemeButtons = colourScheme
		},
		selectColourScheme (value) {
			if (value === COLOUR_SCHEME_IDENTIFIERS.DARK) this.updateGpcBanner('gpcBannerColourSchemeJSON', GPC_BANNER_DARK_COLOUR_SCHEME)
			else if (value === COLOUR_SCHEME_IDENTIFIERS.LIGHT || value === COLOUR_SCHEME_IDENTIFIERS.NONE) this.updateGpcBanner('gpcBannerColourSchemeJSON', GPC_BANNER_LIGHT_COLOUR_SCHEME)
			else this.updateGpcBanner('gpcBannerColourSchemeJSON', GPC_BANNER_DEFAULT_CUSTOM_COLOUR_SCHEME)
		},
		updateColourChanged (element) {
			const colourSchemeJson = this.gpcBanner.gpcBannerColourSchemeJSON
			colourSchemeJson[element.id] = element.colour
			this.updateGpcBanner('gpcBannerColourSchemeJSON', colourSchemeJson)
		},
		updateGpcBanner (property, value) {
			this.$emit('update:gpcBanner', {
				...this.gpcBanner,
				[property]: value
			})
		}
	}
}
</script>
