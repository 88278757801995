import { format } from 'date-fns'
import { DATE_FORMAT } from '../../date-formatting.js'
import { configApi } from '../../http-client.js'
import { COLOUR_SCHEME_IDENTIFIERS } from '../../../../admin-portal/src/components/views/cookie-module/cookies/cookie-appearances-templates/appearance-enums.js'

export const postCookieAppearanceTemplate = cookieAppearanceTemplate => configApi.post('/api/CookieAppearanceTemplates', cookieAppearanceTemplate)

export const putCookieAppearanceTemplate = cookieAppearanceTemplate => configApi.put('/api/CookieAppearanceTemplates', cookieAppearanceTemplate)

export const deleteCookieAppearanceTemplate = cookieAppearanceTemplateId => configApi.delete(`/api/CookieAppearanceTemplates/${cookieAppearanceTemplateId}`)

export const getCookieAppearanceTemplates = () => configApi.get('/api/CookieAppearanceTemplates').then(({ data: { cookieAppearanceTemplates } }) => ({
	cookieAppearanceTemplates: cookieAppearanceTemplates.map(appearanceTemplate => ({
		...appearanceTemplate,
		createdDate: format(new Date(appearanceTemplate.createdDate), DATE_FORMAT),
		preBanner: {
			...appearanceTemplate.preBanner,
			preBannerColourSchemeJSON: JSON.parse(appearanceTemplate.preBanner.preBannerColourSchemeJson),
			preBannerColourSchemeIdentifier: JSON.parse(appearanceTemplate.preBanner.preBannerColourSchemeJson) != null
				? JSON.parse(appearanceTemplate.preBanner.preBannerColourSchemeJson)?.identifier ?? COLOUR_SCHEME_IDENTIFIERS.CUSTOM
				: COLOUR_SCHEME_IDENTIFIERS.NONE
		},
		gpcBanner: {
			...appearanceTemplate.gpcBanner,
			gpcBannerColourSchemeJSON: JSON.parse(appearanceTemplate.gpcBanner.gpcBannerColourSchemeJson),
			gpcBannerColourSchemeIdentifier: JSON.parse(appearanceTemplate.gpcBanner.gpcBannerColourSchemeJson) != null
				? JSON.parse(appearanceTemplate.gpcBanner.gpcBannerColourSchemeJson)?.identifier ?? COLOUR_SCHEME_IDENTIFIERS.CUSTOM
				: COLOUR_SCHEME_IDENTIFIERS.NONE
		},
		banner: {
			...appearanceTemplate.banner,
			bannerColourSchemeJSON: JSON.parse(appearanceTemplate.banner.bannerColourSchemeJson),
			bannerColourSchemeIdentifier: JSON.parse(appearanceTemplate.banner.bannerColourSchemeJson) != null
				? JSON.parse(appearanceTemplate.banner.bannerColourSchemeJson)?.identifier ?? COLOUR_SCHEME_IDENTIFIERS.CUSTOM
				: COLOUR_SCHEME_IDENTIFIERS.NONE
		},
		isSystem: appearanceTemplate.isSystem // Include isSystem property
	}))
}))

export const cookieAppearanceTemplateLogoUpload = logoFile => configApi.post('/api/Assets/UploadBannerAppearancePublicFile', logoFile)
