<template>
	<SectionCard>
		<template #title>
			Banner
		</template>
		<template #subtitle>
			Your Cookie Banner allows users to customise their consent and see what scripts will be loaded into their browser.
		</template>
		<template
			#body
		>
			<v-row>
				<v-col cols="5">
					<!-- ADD permission -->
					<RadioGroup
						radio-group-label="Banner Location *"
						:radio-group-value.sync="banner.bannerLocation"
						:radios="BANNER_LOCATION_RADIOS"
						image-radio-group
						:disabled="!userCanCreateUpdate || iabIsEnabled"
						@update:radioGroupValue="updateBanner('bannerLocation', $event)"
					/>
				</v-col>
				<v-col cols="7">
					<div class="cassie-vertical-md">
						<RadioGroup
							radio-group-label="Colour Scheme *"
							:radio-group-value.sync="banner.bannerColourSchemeIdentifier"
							:radios="COLOUR_SCHEME_RADIOS"
							:disabled="!userCanCreateUpdate || iabIsEnabled"
							tooltip-text="When 'None' is selected, styling will be applied from the page in which the embed code is placed and will not be under the control of Cassie, the Light scheme will be used for the preview."
							@update:radioGroupValue="selectColourScheme($event)"
						/>
						<ColourPicker
							v-if="isCustomScheme && customColourSchemeButtons"
							:elements.sync="customColourSchemeButtons"
							:disabled="!userCanCreateUpdate || iabIsEnabled"
							@update-element-changed="updateColourChanged"
						/>
					</div>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col
					v-if="!showPreBanner"
					cols="2"
				>
					<Toggle
						:value.sync="banner.autoShowModal"
						label="Auto Show Modal"
						:disabled="!userCanCreateUpdate || iabIsEnabled"
						@update:value="updateBanner('autoShowModal', $event)"
					/>
				</v-col>
				<v-col cols="2">
					<Toggle
						:value.sync="banner.displayLogo"
						label="Display Logo"
						:disabled="!userCanCreateUpdate"
						@update:value="updateBanner('displayLogo', $event)"
					/>
				</v-col>
				<v-col cols="2">
					<Toggle
						:value.sync="banner.showToggleLabels"
						label="Show Toggle Labels"
						:disabled="!userCanCreateUpdate || iabIsEnabled"
						@update:value="updateBanner('showToggleLabels', $event)"
					/>
				</v-col>
				<v-col cols="2">
					<Toggle
						:value.sync="banner.showBannerAcceptAllButton"
						label="Show Accept All Button"
						:disabled="!userCanCreateUpdate || iabIsEnabled"
						@update:value="updateBanner('showBannerAcceptAllButton', $event)"
					/>
				</v-col>
				<v-col cols="2">
					<Toggle
						:value.sync="banner.showBannerRejectAllButton"
						label="Show Reject All Button"
						:disabled="!userCanCreateUpdate || iabIsEnabled"
						@update:value="updateBanner('showBannerRejectAllButton', $event)"
					/>
				</v-col>
			</v-row>
			<v-row
				v-if="banner.displayLogo && userCanCreateUpdate"
				dense
			>
				<v-col cols="5">
					<FileInput
						label="Upload Logo"
						accept="image/png, image/jpeg, image/gif"
						tooltip-text="Maximum file size 10mb. Allowed file types: JPG, JPEG, GIF, PNG."
						@selectFile="selectImage"
						@uploadFile="uploadLogo"
					/>
				</v-col>
			</v-row>
			<v-row>
				<v-col
					v-if="banner.imageUrl"
					cols="5"
				>
					<v-img
						max-width="100px"
						:src="banner.imageUrl"
					/>
				</v-col>
			</v-row>
		</template>
	</SectionCard>
</template>

<script>
import SectionCard from '../../../../../../../shared/components/section-card.vue'
import RadioGroup from '../../../../../../../shared/components/radio-group.vue'
import ColourPicker from '../../../../../../../shared/components/colour-picker.vue'
import FileInput from '../../../../../../../shared/components/file-input.vue'
import Toggle from '../../../../../../../shared/components/toggle.vue'
import { cookieAppearanceTemplateLogoUpload } from '../../../../../../../shared/utils/api/cookies/cookie-appearance-templates.js'
import {
	BANNER_LOCATION_RADIOS,
	COLOUR_SCHEME_RADIOS,
	COLOUR_SCHEME_IDENTIFIERS,
	BANNER_CUSTOM_SCHEME_COLOURS,
	BANNER_DARK_COLOUR_SCHEME,
	BANNER_LIGHT_COLOUR_SCHEME,
	BANNER_DEFAULT_CUSTOM_COLOUR_SCHEME
} from './appearance-enums.js'
export default {
	components: {
		SectionCard,
		RadioGroup,
		Toggle,
		ColourPicker,
		FileInput
	},
	props: {
		banner: Object,
		editOrCloneMode: {
			type: Boolean,
			default: false
		},
		isEdit: {
			type: Boolean,
			default: false
		},
		cookieAppearanceTemplateId: {
			type: Number,
			default: null
		},
		showPreBanner: {
			type: Boolean,
			default: true
		},
		userCanCreateUpdate: Boolean,
		iabIsEnabled: Boolean
	},
	setup () {
		return {
			BANNER_LOCATION_RADIOS,
			COLOUR_SCHEME_RADIOS,
			COLOUR_SCHEME_IDENTIFIERS
		}
	},
	data () {
		return {
			customColourSchemeButtons: BANNER_CUSTOM_SCHEME_COLOURS,
			selectedImage: null
		}
	},
	computed: {
		isCustomScheme () {
			return this.banner.bannerColourSchemeIdentifier === COLOUR_SCHEME_IDENTIFIERS.CUSTOM
		}
	},
	created () {
		if (this.editOrCloneMode && this.isCustomScheme) this.setCustomColours()
	},
	methods: {
		setCustomColours () {
			const colourScheme = JSON.parse(JSON.stringify(BANNER_CUSTOM_SCHEME_COLOURS))

			for (const key in this.banner.bannerColourSchemeJSON) {
				const matchingElement = colourScheme.find(({ id }) => id === key)
				if (matchingElement) matchingElement.color = this.banner.bannerColourSchemeJSON[key]
			}
			this.customColourSchemeButtons = colourScheme
		},
		selectImage (file) {
			this.selectedImage = file
		},
		async uploadLogo () {
			const logo = new FormData()
			logo.append('File', this.selectedImage)
			if (this.isEdit) {
				logo.append('CookieAppearanceTemplateId', this.cookieAppearanceTemplateId)
			}
			await cookieAppearanceTemplateLogoUpload(logo).then(response => {
				const fileUrl = response.data.fileUrl
				this.updateBanner('imageUrl', fileUrl)
			})
		},
		selectColourScheme (value) {
			if (value === COLOUR_SCHEME_IDENTIFIERS.DARK) this.updateBanner('bannerColourSchemeJSON', BANNER_DARK_COLOUR_SCHEME)
			else if (value === COLOUR_SCHEME_IDENTIFIERS.LIGHT || value === COLOUR_SCHEME_IDENTIFIERS.NONE) this.updateBanner('bannerColourSchemeJSON', BANNER_LIGHT_COLOUR_SCHEME)
			else this.updateBanner('bannerColourSchemeJSON', BANNER_DEFAULT_CUSTOM_COLOUR_SCHEME)
		},
		updateColourChanged (element) {
			const colourSchemeJson = this.banner.bannerColourSchemeJSON
			colourSchemeJson[element.id] = element.colour
			this.updateBanner('bannerColourSchemeJSON', colourSchemeJson)
		},
		updateBanner (property, value) {
			this.$emit('update:banner', {
				...this.banner,
				[property]: value
			})
		}
	}
}
</script>
