<template>
	<div
		v-if="
			(activeTabUserConsent && hasConsentCookies.length > 0) ||
				(!activeTabUserConsent && hasLegitimateInterestCookies.length > 0)
		"
		class="cassie-cookie-category"
		:class="{
			'cassie-cookie-category--active': cookieCategory.selected === true,
		}"
		@click="$emit('click', cookieCategory)"
	>
		<span>
			{{ cookieCategory.name }}
		</span>
		<BaseSwitch
			v-if="!cookieCategory.isStrictlyNecessary"
			:value="activeTabUserConsent ? false : true"
			:slider-background-off="bannerAppearanceTemplate.bannerColourSchemeJSON.SliderBackgroundOff"
			:slider-background-on="bannerAppearanceTemplate.bannerColourSchemeJSON.SliderBackgroundOn"
			:slider-color="bannerAppearanceTemplate.bannerColourSchemeJSON.SliderToggle"
		>
			<template v-if="bannerAppearanceTemplate.showToggleLabels">
				{{
					cookieCategory.isOptIn ? bannerTextTemplate.optInLabel : bannerTextTemplate.optOutLabel
				}}
			</template>
		</BaseSwitch>
	</div>
</template>

<script>
import BaseSwitch from './base-switch.vue'
export default {
	name: 'cookie-category-panels',
	components: { BaseSwitch },
	props: {
		optInAll: {
			type: Boolean,
			required: true
		},
		activeTabUserConsent: {
			type: Boolean,
			required: true
		},
		cookieCategory: {
			type: Object,
			required: true
		},
		bannerAppearanceTemplate: {
			type: Object,
			required: true
		},
		bannerTextTemplate: {
			type: Object,
			required: true
		}
	},
	data () {
		return {
			expandCategory: false
		}
	},
	computed: {
		hasCookies () {
			return this.cookieCategory.cookies.length > 0
		},
		hasConsentCookies () {
			const cookies = this.cookieCategory.cookies.filter(cookie => {
				return cookie.cookieScriptType !== 'Legitimate Interest'
			})

			return cookies
		},
		hasLegitimateInterestCookies () {
			const cookies = this.cookieCategory.cookies.filter(cookie => {
				return cookie.cookieScriptType === 'Legitimate Interest'
			})

			return cookies
		}
	},
	watch: {
		'cookieCategory.isOptIn': {
			deep: true,
			handler: function (_newVal, _oldVal) {
				this.$emit('changeOptIn', this.cookieCategory)
			}
		}
	}
}
</script>

<style lang="scss">
.cassie-cookie-category {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: var(--cookie-modal-text-color);
  color: var(--cookie-modal-secondary-color);
  border-radius: 4px;
  padding: 0.5rem;
  margin-bottom: 1rem;
  cursor: pointer;
  &--active {
		background-color: var(--cookie-modal-text-color);
  }
}
.cassie-cookie-modal-group {
  display: flex;
  flex-wrap: wrap;
}
.cassie-cookie-modal--group-head-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
}

.cassie-cookie-group--heading {
  margin: 0 2px;
  font-size: 14px;
  line-height: 1.6;
  color: var(--cookie-modal-text-color);
}

.cassie-cookie-group--description {
  width: 75%;
  margin: 0 0 10px 0;
  font-size: 12px;
  line-height: 1.6;
}
</style>
