<template>
	<SectionCard>
		<template #title>
			Pre-Banner
		</template>
		<template #title-action>
			<Toggle
				:value.sync="preBanner.preBannerEnabled"
				label="Show"
				:disabled="!userCanCreateUpdate || iabIsEnabled"
				label-left
			/>
		</template>
		<template #subtitle>
			When your Cookie Banner is first displayed to a user it will
			appear as a Pre-Banner. This gives the user the ability to
			accept all cookies, reject all cookies or to open the Cookie
			Banner in order to customise their consent.
		</template>
		<template #body>
			<v-row>
				<v-col cols="5">
					<!-- add permission -->
					<RadioGroup
						radio-group-label="Pre-Banner Location *"
						:radio-group-value.sync="preBanner.preBannerLocation"
						:radios="PRE_BANNER_LOCATION_RADIOS"
						image-radio-group
						:disabled="!userCanCreateUpdate || iabIsEnabled"
						@update:radioGroupValue="updatePreBanner('preBannerLocation', $event)"
					/>
				</v-col>
				<v-col cols="7">
					<div class="cassie-vertical-md">
						<RadioGroup
							radio-group-label="Colour Scheme *"
							:radio-group-value.sync="preBanner.preBannerColourSchemeIdentifier"
							:radios="COLOUR_SCHEME_RADIOS"
							:disabled="!userCanCreateUpdate || iabIsEnabled"
							tooltip-text="When 'None' is selected, styling will be applied from the page in which the embed code is placed and will not be under the control of Cassie, the Light scheme will be used for the preview."
							@update:radioGroupValue="selectColourScheme($event)"
						/>
						<ColourPicker
							v-if="isCustomScheme && customColourSchemeButtons"
							:elements.sync="customColourSchemeButtons"
							:disabled="!userCanCreateUpdate"
							@update-element-changed="updateColourChanged"
						/>
					</div>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="3">
					<Toggle
						:value.sync="preBanner.showPreBannerAcceptAllButton"
						label="Show Accept All Button"
						:disabled="!userCanCreateUpdate || iabIsEnabled"
						@update:value="updatePreBanner('showPreBannerAcceptAllButton', $event)"
					/>
				</v-col>
				<v-col cols="3">
					<Toggle
						:value.sync="preBanner.showPreBannerRejectAllButton"
						label="Show Reject All Button"
						:disabled="!userCanCreateUpdate || iabIsEnabled"
						@update:value="updatePreBanner('showPreBannerRejectAllButton', $event)"
					/>
				</v-col>
				<v-col cols="3">
					<Toggle
						:value.sync="preBanner.showPreBannerCookieSettingsButton"
						label="Show Cookie Settings Button"
						:disabled="!userCanCreateUpdate || iabIsEnabled"
						@update:value="updatePreBanner('showPreBannerCookieSettingsButton', $event)"
					/>
				</v-col>
			</v-row>
		</template>
	</SectionCard>
</template>

<script>
import SectionCard from '../../../../../../../shared/components/section-card.vue'
import RadioGroup from '../../../../../../../shared/components/radio-group.vue'
import ColourPicker from '../../../../../../../shared/components/colour-picker.vue'
import Toggle from '../../../../../../../shared/components/toggle.vue'
import {
	PRE_BANNER_LOCATION_RADIOS,
	COLOUR_SCHEME_RADIOS,
	COLOUR_SCHEME_IDENTIFIERS,
	PRE_BANNER_CUSTOM_SCHEME_COLOURS,
	PRE_BANNER_DARK_COLOUR_SCHEME,
	PRE_BANNER_LIGHT_COLOUR_SCHEME,
	PRE_BANNER_DEFAULT_CUSTOM_COLOUR_SCHEME
} from './appearance-enums.js'

export default {
	components: {
		SectionCard,
		RadioGroup,
		Toggle,
		ColourPicker
	},
	props: {
		preBanner: Object,
		editOrCloneMode: {
			type: Boolean,
			default: false
		},
		userCanCreateUpdate: Boolean,
		iabIsEnabled: Boolean
	},
	setup () {
		return {
			PRE_BANNER_LOCATION_RADIOS,
			COLOUR_SCHEME_RADIOS,
			COLOUR_SCHEME_IDENTIFIERS
		}
	},
	data () {
		return {
			customColourSchemeButtons: PRE_BANNER_CUSTOM_SCHEME_COLOURS
		}
	},
	computed: {
		isCustomScheme () {
			return this.preBanner.preBannerColourSchemeIdentifier === COLOUR_SCHEME_IDENTIFIERS.CUSTOM
		}
	},
	created () {
		if (this.editOrCloneMode && this.isCustomScheme) this.setCustomColours()
	},
	methods: {
		setCustomColours () {
			const colourScheme = JSON.parse(JSON.stringify(PRE_BANNER_CUSTOM_SCHEME_COLOURS))

			for (const key in this.preBanner.preBannerColourSchemeJSON) {
				const matchingElement = colourScheme.find(({ id }) => id === key)
				if (matchingElement) matchingElement.color = this.preBanner.preBannerColourSchemeJSON[key]
			}
			this.customColourSchemeButtons = colourScheme
		},
		selectColourScheme (value) {
			if (value === COLOUR_SCHEME_IDENTIFIERS.DARK) this.updatePreBanner('preBannerColourSchemeJSON', PRE_BANNER_DARK_COLOUR_SCHEME)
			else if (value === COLOUR_SCHEME_IDENTIFIERS.LIGHT || value === COLOUR_SCHEME_IDENTIFIERS.NONE) this.updatePreBanner('preBannerColourSchemeJSON', PRE_BANNER_LIGHT_COLOUR_SCHEME)
			else this.updatePreBanner('preBannerColourSchemeJSON', PRE_BANNER_DEFAULT_CUSTOM_COLOUR_SCHEME)
		},
		updateColourChanged (element) {
			const colourSchemeJson = this.preBanner.preBannerColourSchemeJSON
			colourSchemeJson[element.id] = element.colour
			this.updatePreBanner('preBannerColourSchemeJSON', colourSchemeJson)
		},
		updatePreBanner (property, value) {
			this.$emit('update:preBanner', {
				...this.preBanner,
				[property]: value
			})
		}
	}
}
</script>
