<template>
	<!-- eslint-disable max-lines-->
	<div>
		<v-row class="mx-1 mb-2">
			<v-col cols="7">
				<v-tabs
					v-show="previewTypes.length > 1"
					v-model="selectedPreview"
					background-color="blue darken-2"
					color="white"
					:class="$style.cassie_preview_type_tabs_container"
					:active-class="$style.cassie_preview_type_tab_active"
					:show-arrows="false"
					grow
					:value="selectedPreview"
				>
					<v-tab
						v-for="(previewType, index) in previewTypes"
						:key="index"
						:href="'#'+previewType.value"
						:class="$style.cassie_preview_type_tab"
					>
						{{ previewType.text }}
					</v-tab>
				</v-tabs>
			</v-col>
			<v-spacer />
			<v-col>
				<div class="float-right">
					<v-tabs
						hide-slider
						:class="$style.cassie_device_type_tabs_container"
						:active-class="$style.cassie_device_type_tab_active"
						height="30"
						color="blue darken-2"
					>
						<v-tab
							v-for="deviceType in deviceTypes"
							:key="deviceType"
							:class="$style.cassie_device_type_tab"
							class="px-1 font-weight-bold"
							@click="selectedDevice = deviceType.toLowerCase()"
						>
							{{ deviceType }}
						</v-tab>
					</v-tabs>
				</div>
			</v-col>
		</v-row>
		<v-tabs-items v-model="selectedPreview">
			<v-tab-item
				value="0"
				transition="none"
			>
				<v-row>
					<v-col
						cols="12"
						class="px-6"
					>
						<div
							:class="[
								$style.cassie_preview_container,
								$style['cassie_preview_container--' + selectedDevice],
								cookieAppearanceTemplate.forceBannerInteraction ? $style.cassie_preview_container_background : '',
							]"
							class="d-flex position-relative justify-center mx-auto"
						>
							<div
								:class="[
									selectedPreBannerAppearance,
									$style['cassie-pre-banner--' + selectedDevice],
								]"
								:style="{
									backgroundColor: cookieAppearanceTemplate.preBanner.preBannerColourSchemeJSON.BackgroundColour,
									borderColor: cookieAppearanceTemplate.preBanner.preBannerColourSchemeJSON.SecondaryColour,
								}"
								class="d-flex  flex-wrap"
							>
								<p
									v-dompurify-html="cookieTextTemplate.preBanner.preBannerText"
									:class="[
										$style['cassie-pre-banner-text'],
										$style['cassie-pre-banner-text' + '--' + selectedPreBannerLocation],
										$style['cassie-pre-banner-text' + '--' + selectedDevice],
									]"
									:style="{
										color: cookieAppearanceTemplate.preBanner.preBannerColourSchemeJSON.TextColour,
									}"
								/>
								<div
									:class="[
										$style[
											'cassie-pre-banner-button-container' + '--' + selectedPreBannerLocation
										],
										$style[
											'cassie-pre-banner-button-container' + '--' + selectedDevice
										]
									]"
								>
									<button
										v-if="cookieAppearanceTemplate.preBanner.showPreBannerAcceptAllButton"
										id="cassie_accept_all_pre_banner"
										:class="[
											$style['cassie-accept-all'],
											$style['cassie-pre-banner-button'],
											$style['cassie-pre-banner-button' + '--' + selectedPreBannerLocation],
											$style['cassie-pre-banner-button' + '--' + selectedDevice],
										]"
										:style="{
											color: cookieAppearanceTemplate.preBanner.preBannerColourSchemeJSON.AcceptAllButtonTextColour,
											backgroundColor: cookieAppearanceTemplate.preBanner.preBannerColourSchemeJSON.AcceptAllButtonColour,
											borderColor: cookieAppearanceTemplate.preBanner.preBannerColourSchemeJSON.AcceptAllButtonColour,
											poinerEvents: 'none',
										}"
										disabled
									>
										{{ cookieTextTemplate.preBanner.acceptAllButtonText }}
									</button>
									<button
										v-if="cookieAppearanceTemplate.preBanner.showPreBannerRejectAllButton"
										id="cassie_reject_all_pre_banner"
										:class="[
											$style['cassie-accept-all'],
											$style['cassie-pre-banner-button'],
											$style['cassie-pre-banner-button' + '--' + selectedPreBannerLocation],
										]"
										:style="{
											color: cookieAppearanceTemplate.preBanner.preBannerColourSchemeJSON.RejectAllButtonTextColour,
											backgroundColor: cookieAppearanceTemplate.preBanner.preBannerColourSchemeJSON.RejectAllButtonColour,
											borderColor: cookieAppearanceTemplate.preBanner.preBannerColourSchemeJSON.RejectAllButtonColour,
										}"
										disabled
									>
										{{ cookieTextTemplate.preBanner.rejectAllButtonText }}
									</button>
									<button
										v-show="cookieAppearanceTemplate.preBanner.showPreBannerCookieSettingsButton"
										:class="[
											$style['cassie-view-all'],
											$style['cassie-pre-banner-button'],
											$style['cassie-pre-banner-button' + '--' + selectedPreBannerLocation],
										]"
										:style="{
											color: cookieAppearanceTemplate.preBanner.preBannerColourSchemeJSON.CustomiseAllButtonTextColour,
											backgroundColor: cookieAppearanceTemplate.preBanner.preBannerColourSchemeJSON.CustomiseAllButtonColour,
											borderColor: cookieAppearanceTemplate.preBanner.preBannerColourSchemeJSON.CustomiseAllButtonColour,
										}"
										disabled
									>
										<span :class="$style['cassie-view-all--button--icon']">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												enable-background="new 0 0 24 24"
												height="16"
												viewBox="0 0 24 24"
												width="16"
											>
												<g>
													<path
														d="M0,0h24v24H0V0z"
														fill="none"
													/>
													<path
														class="cassie-view-all--button--icon"
														d="M19.14,12.94c0.04-0.3,0.06-0.61,0.06-0.94c0-0.32-0.02-0.64-0.07-0.94l2.03-1.58c0.18-0.14,0.23-0.41,0.12-0.61 l-1.92-3.32c-0.12-0.22-0.37-0.29-0.59-0.22l-2.39,0.96c-0.5-0.38-1.03-0.7-1.62-0.94L14.4,2.81c-0.04-0.24-0.24-0.41-0.48-0.41 h-3.84c-0.24,0-0.43,0.17-0.47,0.41L9.25,5.35C8.66,5.59,8.12,5.92,7.63,6.29L5.24,5.33c-0.22-0.08-0.47,0-0.59,0.22L2.74,8.87 C2.62,9.08,2.66,9.34,2.86,9.48l2.03,1.58C4.84,11.36,4.8,11.69,4.8,12s0.02,0.64,0.07,0.94l-2.03,1.58 c-0.18,0.14-0.23,0.41-0.12,0.61l1.92,3.32c0.12,0.22,0.37,0.29,0.59,0.22l2.39-0.96c0.5,0.38,1.03,0.7,1.62,0.94l0.36,2.54 c0.05,0.24,0.24,0.41,0.48,0.41h3.84c0.24,0,0.44-0.17,0.47-0.41l0.36-2.54c0.59-0.24,1.13-0.56,1.62-0.94l2.39,0.96 c0.22,0.08,0.47,0,0.59-0.22l1.92-3.32c0.12-0.22,0.07-0.47-0.12-0.61L19.14,12.94z M12,15.6c-1.98,0-3.6-1.62-3.6-3.6 s1.62-3.6,3.6-3.6s3.6,1.62,3.6,3.6S13.98,15.6,12,15.6z"
													/>
												</g>
											</svg>
										</span>
										<span
											:id="$style.cassie_view_all_pre_banner"
										>
											{{ cookieTextTemplate.preBanner.cookieSettingsButtonText }}
										</span>
									</button>
								</div>
							</div>
						</div>
					</v-col>
				</v-row>
			</v-tab-item>
			<v-tab-item
				value="1"
				transition="none"
			>
				<v-row>
					<v-col
						cols="12"
						class="px-6"
					>
						<div
							:class="[
								$style.cassie_preview_container,
								$style['cassie_preview_container--' + selectedDevice],
								cookieAppearanceTemplate.forceBannerInteraction ? $style.cassie_preview_container_background : ''
							]"
							class="d-flex position-relative justify-center mx-auto"
						>
							<div
								:class="[
									selectedGpcAppearance,
									$style['cassie-pre-banner--' + selectedDevice],
								]"
								:style="{
									backgroundColor: cookieAppearanceTemplate.gpcBanner.gpcBannerColourSchemeJSON.BackgroundColour,
									borderColor: cookieAppearanceTemplate.gpcBanner.gpcBannerColourSchemeJSON.SecondaryColour,
								}"
								class="d-flex flex-wrap"
							>
								<div
									:class="[
										$style['cassie-pre-banner-text'],
										$style['cassie-pre-banner-text' + '--' + selectedGpcBannerLocation],
										$style['cassie-pre-banner-text' + '--' + selectedDevice],
										'pl-0'
									]"
									:style="{
										color: cookieAppearanceTemplate.gpcBanner.gpcBannerColourSchemeJSON.TextColour,
									}"
								>
									<p
										v-dompurify-html="cookieTextTemplate.gpcBanner.gpcBannerTitle"
										:class="[
											$style['cassie-pre-banner-text'],
											$style['cassie-pre-banner-text' + '--' + selectedGpcBannerLocation],
											$style['cassie-pre-banner-text' + '--' + selectedDevice],
											'font-weight-bold'
										]"
										:style="{
											color: cookieAppearanceTemplate.gpcBanner.gpcBannerColourSchemeJSON.TextColour,
										}"
									/>
									<p
										v-dompurify-html="cookieTextTemplate.gpcBanner.gpcBannerText"
										:class="[
											$style['cassie-pre-banner-text'],
											$style['cassie-pre-banner-text' + '--' + selectedGpcBannerLocation],
											$style['cassie-pre-banner-text' + '--' + selectedDevice]
										]"
										:style="{
											color: cookieAppearanceTemplate.gpcBanner.gpcBannerColourSchemeJSON.TextColour,
										}"
									/>
								</div>
								<div
									:class="[
										$style[
											'cassie-pre-banner-button-container' + '--' + selectedGpcBannerLocation
										],
										$style[
											'cassie-pre-banner-button-container' + '--' + selectedDevice
										],
									]"
								>
									<button
										v-if="cookieAppearanceTemplate.gpcBanner.showGpcBannerAcceptAllButton"
										id="cassie_accept_all_pre_banner"
										:class="[
											$style['cassie-accept-all'],
											$style['cassie-pre-banner-button'],
											$style['cassie-pre-banner-button' + '--' + selectedGpcBannerLocation],
											$style['cassie-pre-banner-button' + '--' + selectedDevice],
										]"
										:style="{
											color: cookieAppearanceTemplate.gpcBanner.gpcBannerColourSchemeJSON.AcceptAllButtonTextColour,
											backgroundColor: cookieAppearanceTemplate.gpcBanner.gpcBannerColourSchemeJSON.AcceptAllButtonColour,
											borderColor: cookieAppearanceTemplate.gpcBanner.gpcBannerColourSchemeJSON.SecondaryColour,
											poinerEvents: 'none',
										}"
										disabled
									>
										{{ cookieTextTemplate.gpcBanner.acceptAllButtonText }}
									</button>
									<button
										v-if="cookieAppearanceTemplate.gpcBanner.showGpcBannerAcceptAllExcGpcButton"
										id="cassie_reject_all_pre_banner"
										:class="[
											$style['cassie-accept-all'],
											$style['cassie-pre-banner-button'],
											$style['cassie-pre-banner-button' + '--' + selectedGpcBannerLocation],
										]"
										:style="{
											color: cookieAppearanceTemplate.gpcBanner.gpcBannerColourSchemeJSON.AcceptAllExcGpcButtonTextColour,
											backgroundColor: cookieAppearanceTemplate.gpcBanner.gpcBannerColourSchemeJSON.AcceptAllExcGpcButtonColour,
											borderColor: cookieAppearanceTemplate.gpcBanner.gpcBannerColourSchemeJSON.SecondaryColour,
										}"
										disabled
									>
										{{ cookieTextTemplate.gpcBanner.acceptAllExcGpcButtonText }}
									</button>
									<button
										v-if="cookieAppearanceTemplate.gpcBanner.showGpcBackButton"
										:class="[
											$style['cassie-view-all'],
											$style['cassie-pre-banner-button'],
											$style['cassie-pre-banner-button' + '--' + selectedGpcBannerLocation],
										]"
										:style="{
											color: cookieAppearanceTemplate.gpcBanner.gpcBannerColourSchemeJSON.BackButtonTextColour,
											backgroundColor: cookieAppearanceTemplate.gpcBanner.gpcBannerColourSchemeJSON.BackButtonColour,
											borderColor: cookieAppearanceTemplate.gpcBanner.gpcBannerColourSchemeJSON.SecondaryColour,
										}"
										disabled
									>
										<span
											:id="$style.cassie_view_all_pre_banner"
										>{{ cookieTextTemplate.gpcBanner.backButtonText }}
										</span>
									</button>
								</div>
							</div>
						</div>
					</v-col>
				</v-row>
			</v-tab-item>
			<v-tab-item
				value="2"
				transition="none"
			>
				<v-row>
					<v-col
						cols="12"
						class="px-6"
					>
						<div
							:class="[
								$style.cassie_preview_container,
								$style['cassie_preview_container--' + selectedDevice],
								cookieAppearanceTemplate.forceBannerInteraction ? $style.cassie_preview_container_background : ''
							]"
							class="d-flex position-relative align-end mx-auto"
						>
							<button
								id="cassie_manage_cookies_button"
								:class="[$style['cassie-manage-cookies--button']]"
								:style="{
									color: cookieAppearanceTemplate.banner.bannerColourSchemeJSON.SubmitPreferencesButtonTextColour,
									backgroundColor: cookieAppearanceTemplate.banner.bannerColourSchemeJSON.SubmitPreferencesButtonColour,
									borderColor: cookieAppearanceTemplate.banner.bannerColourSchemeJSON.SubmitPreferencesButtonColour
								}"
								disabled
							>
								Open Cookie Settings
							</button>
						</div>
					</v-col>
				</v-row>
			</v-tab-item>
			<v-tab-item
				value="3"
				transition="none"
			>
				<div
					:style="{ '--cookie-modal-active-tab': cookieAppearanceTemplate.banner.bannerColourSchemeJSON.SliderBackgroundOn }"
				>
					<v-row class="">
						<v-col
							cols="12"
							class="px-6"
						>
							<div
								class="cassie_preview_container position-relative"
								:class="[
									'cassie_preview_container--' + selectedDevice,
									cookieAppearanceTemplate.forceBannerInteraction ? $style.cassie_preview_container_background : ''
								]"
								:style="{
									'--cookie-modal-secondary-color': cookieAppearanceTemplate.banner.bannerColourSchemeJSON.SecondaryColour,
									'--cookie-modal-background-color': cookieAppearanceTemplate.banner.bannerColourSchemeJSON.BackgroundColour,
									'--cookie-modal-text-color': cookieAppearanceTemplate.banner.bannerColourSchemeJSON.TextColour,
									'--cassie-submit-preferences-button-color':
										cookieAppearanceTemplate.banner.bannerColourSchemeJSON.SubmitPreferencesButtonColour,
									'--cassie-submit-preferences-button-text-color':
										cookieAppearanceTemplate.banner.bannerColourSchemeJSON.SubmitPreferencesButtonTextColour,
								}"
							>
								<div
									class="cassie-cookie-modal"
									:class="[
										'cassie-cookie-modal--' + selectedBannerAppearance,
										'cassie-cookie-modal--' +
											selectedBannerAppearance +
											'--' +
											selectedDevice,
									]"
								>
									<div class="cookie-modal cookie-modal--mobile">
										<div class="cassie-cookie-modal-header">
											<div
												v-show="cookieAppearanceTemplate.banner.displayLogo"
												:class="[
													'cassie-modal-header',
													'cassie-modal-header--' + selectedBannerAppearance,
												]"
											>
												<v-img
													:src="cookieAppearanceTemplate.banner.imageUrl"
													max-width="100px"
													:class="[
														'cassie-cookie-modal--header-image',
														'cassie-cookie-modal--header-image--' +
															selectedBannerAppearance,
													]"
												/>
												<span class="cassie-close-modal--container" />
											</div><p
												v-dompurify-html="cookieTextTemplate.headerHtml"
												:class="'cassie-cookie-modal--header--content'"
											/>
											<div
												id="cassie_accept_all_cookies"
												:class="'cassie-cookie-modal--accept-all'"
											>
												<span
													id="cassie_accept_all_cookies_text"
													class="cassie-cookie-modal--accept-all--text"
												>
													{{ cookieTextTemplate.banner.acceptAllButtonText }}
												</span>
												<!-- Main Accept All Cookies Accept/Reject Switch -->
												<BaseSwitch
													v-model="optInAll"
													:slider-background-off="cookieAppearanceTemplate.banner.bannerColourSchemeJSON.SliderBackgroundOff"
													:slider-background-on="cookieAppearanceTemplate.banner.bannerColourSchemeJSON.SliderBackgroundOn"
													:slider-color="cookieAppearanceTemplate.banner.bannerColourSchemeJSON.SliderToggle"
												>
													<template v-if="cookieAppearanceTemplate.banner.showToggleLabels">
														{{
															optInAll ? cookieTextTemplate.banner.optInLabel : cookieTextTemplate.banner.optOutLabel
														}}
													</template>
												</BaseSwitch>
											</div>
											<div
												v-if="showTabs"
												class="d-flex space-between"
											>
												<div
													:class="{ 'tab--active': selectedUserConsentTab }"
													class="tab"
													@click="selectedUserConsentTab = true"
												>
													{{ cookieTextTemplate.banner.userConsentTabLabelText }}
												</div>
												<div
													:class="{ 'tab--active': !selectedUserConsentTab }"
													class="tab"
													@click="selectedUserConsentTab = false"
												>
													{{ cookieTextTemplate.banner.legitimateInterestTabLabelText }}
												</div>
											</div>
										</div><div
											class="cassie-cookie-modal--main"
											:class="'cassie-cookie-modal--main--' + selectedBannerAppearance"
											bis_skin_checked="1"
										>
											<div
												id="cassie_cookie_modal_cookies_container"
												class="cassie-cookie-modal--cookies--container"
												bis_skin_checked="1"
											>
												<div
													class="cassie-cookie-modal--tabs--container"
													bis_skin_checked="1"
													style="display: none;"
												>
													<button
														id="cassie_consent_button"
														class="cassie-tab--button cassie-active--button"
													>
														User Consent
													</button>
													<button
														id="cassie_legitimate_interests_button"
														class="cassie-tab--button"
													>
														Legitimate Interest
													</button>
												</div><div
													id="cassie_consent_tab_cookies"
													class="cassie-cookie-modal--tab-group"
													bis_skin_checked="1"
												>
													<div v-if="cookieAppearanceTemplate.banner.bannerLocation === BANNER_LOCATIONS.PANELS">
														<CookieCategoryPanels
															v-for="cookieCategory in cookieCollectionTemplate.cookieCategories"
															:key="cookieCategory.id"
															:cookie-category="cookieCategory"
															:banner-appearance-template="cookieAppearanceTemplate.banner"
															:banner-text-template="cookieTextTemplate.banner"
															:active-tab-user-consent="selectedUserConsentTab"
															:opt-in-all="optInAll"
															@click="selectCookieCategory"
															@changeOptIn="categoryOptIn"
														/>
													</div>
													<CookieCategory
														v-for="cookieCategory in cookieCollectionTemplate.cookieCategories"
														v-else
														:key="cookieCategory.id"
														:cookie-category="cookieCategory"
														:banner-appearance-template="cookieAppearanceTemplate.banner"
														:banner-text-template="cookieTextTemplate.banner"
														:ripple="false"
														:active-tab-user-consent="selectedUserConsentTab"
														:gpc-enabled="cookieAppearanceTemplate.gpcBanner.gpcBannerEnabled"
														:opt-in-all="optInAll"
													/>
												</div>
												<div
													id="cassie_legitimate_interests_tab_cookies"
													class="cassie-cookie-modal--tab-group"
													bis_skin_checked="1"
													style="display: none;"
												/>
											</div>
										</div>
										<div
											v-dompurify-html="cookieTextTemplate.footerHtml"
											:class="'footer'"
										/>
										<div>
											<button
												v-if="cookieAppearanceTemplate.banner.bannerLocation === BANNER_LOCATIONS.PANELS"
												:class="'save-preferences'"
												@click.prevent.stop=""
											>
												{{ cookieTextTemplate.banner.submitPreferencesButtonText }}
											</button>
										</div>
									</div>

									<div
										v-if="cookieAppearanceTemplate.banner.bannerLocation === BANNER_LOCATIONS.PANELS"
										class="details"
										:class="'details--' + selectedDevice"
									>
										<div class="details-header">
											{{
												selectedCookieCategory.name
											}}
										</div>
										<div
											id="cassie_strictly_necessary_description"
											v-dompurify-html="selectedCookieCategory.description"
											class="cassie-strictly-necessary--description cassie-cookie-group--description"
										/>
										<ul
											class="cookie-list"
											:class="'cookie-list--' + selectedDevice"
										>
											<li
												v-for="cookie in selectedCookieCategory.cookies"
												:key="cookie.id"
												class="cookie"
											>
												<template
													v-if="
														(cookie.cookieScriptType !== 'Legitimate Interest' &&
															selectedUserConsentTab) ||
															(cookie.cookieScriptType === 'Legitimate Interest' &&
																!selectedUserConsentTab)
													"
												>
													<div class="cookie-header">
														<div
															class="cookie-name"
														>
															{{ cookie.name }}
															<span
																v-if="cookie.gpcEnabled && cookieAppearanceTemplate.gpcBanner.gpcBannerEnabled"
																class="cassie--gpc--flag"
															>GPC</span>
														</div>
														<BaseSwitch
															v-if="!selectedCookieCategory.isStrictlyNecessary"
															v-model="cookie.isOptIn"
															:slider-background-off="cookieAppearanceTemplate.banner.bannerColourSchemeJSON.SliderBackgroundOff"
															:slider-background-on="cookieAppearanceTemplate.banner.bannerColourSchemeJSON.SliderBackgroundOn"
															:slider-color="cookieAppearanceTemplate.banner.bannerColourSchemeJSON.SliderToggle"
														>
															<template v-if="cookieAppearanceTemplate.banner.showToggleLabels">
																{{
																	cookie.isOptIn
																		? cookieTextTemplate.banner.optInLabel
																		: cookieTextTemplate.banner.optOutLabel
																}}
															</template>
														</BaseSwitch>
													</div>
													<div
														v-dompurify-html="cookie.description"
														class="cookie-description"
													/>
												</template>
											</li>
										</ul>
										<div
											v-if="cookieAppearanceTemplate.banner.bannerLocation === BANNER_LOCATIONS.PANELS"
											class="d-flex align-center"
											:class="'footer-extra-image--' + selectedBannerAppearance"
										>
											<v-img
												v-if="!isLightTheme"
												max-width="75"
												src="../../../../../../../shared/assets/cassie-logo-white.svg"
											/>
											<v-img
												v-else
												max-width="75"
												src="../../../../../../../shared/assets/cassie-logo-black.svg"
											/>
										</div>
									</div>
									<div
										v-else
										class="d-flex space-between footer-extra"
										:class="'footer-extra--' + selectedBannerAppearance"
									>
										<button
											:class="'save-preferences'"
											@click.prevent.stop=""
										>
											{{ cookieTextTemplate.banner.submitPreferencesButtonText }}
										</button>

										<div
											v-show="cookieAppearanceTemplate.banner.bannerLocation !== BANNER_LOCATIONS.PANELS"
											class="d-flex align-center"
										>
											<v-img
												v-if="!isLightTheme"
												max-width="75"
												src="../../../../../../../shared/assets/cassie-logo-white.svg"
											/>
											<v-img
												v-else
												max-width="75"
												src="../../../../../../../shared/assets/cassie-logo-black.svg"
											/>
										</div>
									</div>
								</div>
							</div>
						</v-col>
					</v-row>
				</div>
			</v-tab-item>
		</v-tabs-items>
	</div>
</template>

<script>
/* eslint-disable max-lines */
import { PRE_BANNER_LOCATIONS, BANNER_LOCATIONS } from '../cookie-appearances-templates/appearance-enums.js'
import BaseSwitch from './base-switch.vue'
import CookieCategory from './cookie-category.vue'
import CookieCategoryPanels from './cookie-category-panels.vue'

export default {
	components: {
		BaseSwitch,
		CookieCategory,
		CookieCategoryPanels
	},
	props: {
		cookieAppearanceTemplate: Object,
		cookieTextTemplate: Object,
		cookieCollectionTemplate: Object,
		colourScheme: {
			type: Object,
			default: null
		}
	},
	setup () {
		return {
			PRE_BANNER_LOCATIONS,
			BANNER_LOCATIONS
		}
	},
	data () {
		return {
			deviceTypes: ['Desktop', 'Tablet', 'Mobile'],
			selectedDevice: 'desktop',
			selectedPreview: '0',
			previewTypes: [
				{ text: 'Pre-Banner Preview', value: '0' },
				{ text: 'GPC Banner Preview', value: '1' },
				{ text: 'Cookie Selection Button Preview', value: '2' },
				{ text: 'Banner Preview', value: '3' }
			],
			optInAll: false,
			selectedUserConsentTab: true,
			tempCookieCategories: [],
			selectedCookieCategory: {}
		}
	},
	computed: {
		selectedPreBannerAppearance () {
			let selectedAppearance = ''
			switch (this.cookieAppearanceTemplate.preBanner.preBannerLocation) {
				case PRE_BANNER_LOCATIONS.BOTTOM:
					selectedAppearance = this.$style['cassie-pre-banner--bottom']
					break
				case PRE_BANNER_LOCATIONS.CENTRE:
					selectedAppearance = this.$style['cassie-pre-banner--center']
					break
				case PRE_BANNER_LOCATIONS.TOP:
					selectedAppearance = this.$style['cassie-pre-banner--top']
					break
				default:
					selectedAppearance = this.$style['cassie-pre-banner--center']
					break
			}
			return selectedAppearance
		},
		selectedPreBannerLocation () {
			let selectedAppearance = ''
			switch (this.cookieAppearanceTemplate.preBanner.preBannerLocation) {
				case PRE_BANNER_LOCATIONS.BOTTOM:
					selectedAppearance = 'bottom'
					break
				case PRE_BANNER_LOCATIONS.CENTRE:
					selectedAppearance = 'center'
					break
				case PRE_BANNER_LOCATIONS.TOP:
					selectedAppearance = 'top'
					break
				default:
					selectedAppearance = 'center'
					break
			}
			return selectedAppearance
		},
		selectedGpcAppearance () {
			let selectedAppearance = ''
			switch (this.cookieAppearanceTemplate.gpcBanner.gpcBannerLocation) {
				case PRE_BANNER_LOCATIONS.BOTTOM:
					selectedAppearance = this.$style['cassie-pre-banner--bottom']
					break
				case PRE_BANNER_LOCATIONS.CENTRE:
					selectedAppearance = this.$style['cassie-pre-banner--center']
					break
				case PRE_BANNER_LOCATIONS.TOP:
					selectedAppearance = this.$style['cassie-pre-banner--top']
					break
				default:
					selectedAppearance = this.$style['cassie-pre-banner--center']
					break
			}
			return selectedAppearance
		},
		selectedGpcBannerLocation () {
			let selectedAppearance = ''
			switch (this.cookieAppearanceTemplate.gpcBanner.gpcBannerLocation) {
				case PRE_BANNER_LOCATIONS.BOTTOM:
					selectedAppearance = 'bottom'
					break
				case PRE_BANNER_LOCATIONS.CENTRE:
					selectedAppearance = 'center'
					break
				case PRE_BANNER_LOCATIONS.TOP:
					selectedAppearance = 'top'
					break
				default:
					selectedAppearance = 'center'
					break
			}
			return selectedAppearance
		},
		selectedBannerAppearance () {
			let selectedAppearance = ''
			switch (this.cookieAppearanceTemplate.banner.bannerLocation) {
				case BANNER_LOCATIONS.LEFT:
					selectedAppearance = 'left'
					break
				case BANNER_LOCATIONS.MIDDLE:
					selectedAppearance = 'middle'
					break
				case BANNER_LOCATIONS.PANELS:
					selectedAppearance = 'panels'
					break
				case BANNER_LOCATIONS.RIGHT:
					selectedAppearance = 'right'
					break
				default:
					selectedAppearance = 'left'
					break
			}
			return selectedAppearance
		},
		selectedBannerLocation () {
			let selectedAppearance = ''
			switch (this.cookieAppearanceTemplate.banner.bannerLocation) {
				case BANNER_LOCATIONS.LEFT:
					selectedAppearance = 'left'
					break
				case BANNER_LOCATIONS.MIDDLE:
					selectedAppearance = 'middle'
					break
				case BANNER_LOCATIONS.PANELS:
					selectedAppearance = 'panels'
					break
				case BANNER_LOCATIONS.RIGHT:
					selectedAppearance = 'right'
					break
				default:
					selectedAppearance = 'left'
					break
			}
			return selectedAppearance
		},
		isLightTheme () {
			return this.cookieAppearanceTemplate.banner.bannerColourSchemeJSON.BackgroundColour === '#FFFFFF'
		},
		showTabs () {
			let containsLegitimateInterestCookies = false
			this.cookieCollectionTemplate.cookieCategories.forEach(category => {
				if (category.cookies.find(cookie => cookie.cookieScriptType === 'Legitimate Interest')) {
					containsLegitimateInterestCookies = true
				}
			})
			return containsLegitimateInterestCookies
		}
	},
	watch: {
		optInAll (val) {
			if (!val && (this.tempCookieCategories.length > 0)) {
				this.cookieCategories = JSON.parse(
					JSON.stringify(this.tempCookieCategories)
				)
				return
			}
			this.cookieCategories = this.cookieCollectionTemplate.cookieCategories.map(category => {
				category.isOptIn = val
				category.cookies = category.cookies.map(cookie => {
					cookie.isOptIn = val
					return cookie
				})
				return category
			})
		},
		selectedUserConsentTab (val) {
			if (!val) {
				this.tempCookieCategories = JSON.parse(
					JSON.stringify(this.cookieCollectionTemplate.cookieCategories)
				)
				this.optInAll = true
			} else {
				this.optInAll = false
			}
		}
	},
	created () {
		this.selectedCookieCategory = this.cookieCollectionTemplate.cookieCategories.find(x => x.selected)
	},
	methods: {
		selectCookieCategory (cookieCategory) {
			this.cookieCollectionTemplate.cookieCategories.forEach(x => {
				x.selected = false
				if (x.name === cookieCategory.name) {
					x.selected = true
				}
			})
			this.selectedCookieCategory = cookieCategory
		},
		categoryOptIn (val) {
			this.cookieCollectionTemplate.cookieCategories.forEach(x => {
				if (x.name === val.name) {
					x.cookies = x.cookies.map(c => {
						c.isOptIn = val.isOptIn
						return c
					})
				}
			})
		}
	}
}
</script>

<style lang="scss" module>
.cassie-pre-banner-button {
  width: 70%;
  padding: 10px 0;
  margin: 8px 0;
  font-family: 'poppins', sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: var(--cassie-preview-text-color);
  overflow-wrap: break-word;
  cursor: pointer;
  border-color: var(--cassie-preview-primary-color);
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
}
.cassie-pre-banner-button--bottom {
  width: 30%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: 2%;
  margin-left: 2%;
}
.cassie-pre-banner-button--top {
  width: 30%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: 2%;
  margin-left: 2%;
}
.cassie-pre-banner .cassie-view-all {
  margin-top: 20px;
  background-color: var(--cassie-preview-primary-color);
  border: 0;
  border-color: var(--cassie-preview-primary-color);
  border-bottom: 1px solid;
}
.cassie-view-all--button--icon {
  vertical-align: middle;
  fill: #fff;
}
.cassie-pre-banner .cassie-accept-all {
  color: #fff;
  background-color: #071725;
  border: 1px solid;
  border-color: var(--cassie-preview-primary-color);
  border-top-style: solid;
  border-top-width: 1px;
  border-right-style: solid;
  border-right-width: 1px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-left-style: solid;
  border-left-width: 1px;
}
.cassie-pre-banner--bottom {
  align-self: flex-end;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  background-color: var(--cassie-preview-secondary-color);
  border-right-color: var(--cassie-preview-primary-color);
  border-right-style: solid;
  border-right-width: 15px;
  border-left-color: var(--cassie-preview-primary-color);
  border-left-style: solid;
  border-left-width: 15px;
}
.cassie-pre-banner--top {
  align-self: flex-start;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  background-color: var(--cassie-preview-secondary-color);
  border-right-color: var(--cassie-preview-primary-color);
  border-right-style: solid;
  border-right-width: 15px;
  border-left-color: var(--cassie-preview-primary-color);
  border-left-style: solid;
  border-left-width: 15px;
}
.cassie-pre-banner-text {
  align-self: center;
  font-size: 13px;
  color: var(--cassie-preview-text-color);
}
.cassie-pre-banner-text--mobile {
  flex: 100%;
}
.cassie-pre-banner-text--tablet {
  flex: 100%;
}
.cassie-pre-banner-text--desktop {
  flex: 60%;
}
.cassie-pre-banner-text--bottom {
  margin-bottom: 0 !important;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.6;
}
.cassie-pre-banner-button-container--mobile {
  flex: 100%;
}
.cassie-pre-banner-button-container--tablet {
  flex: 100%;
}
.cassie-pre-banner-button-container--desktop {
  flex: 40%;
}
.cassie-pre-banner-button-container--top {
  display: flex;
  flex-direction: row;
  width: 40%;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.6;
  color: var(--cassie-preview-text-color);
}
.cassie-pre-banner-button-container--bottom {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.6;
  color: var(--cassie-preview-text-color);
}
.cassie-pre-banner--center {
  flex-wrap: nowrap;
  align-self: center;
  justify-content: center;
  width: 100%;
  min-height: 15rem;
  padding: 20px 0;
  margin: 0 30%;
  background-color: var(--cassie-preview-secondary-color);
  border-right: 15px solid;
  border-right-color: var(--cassie-preview-primary-color);
  border-left: 15px solid;
  border-left-color: var(--cassie-preview-primary-color);
  border-radius: 10px;
}
.cassie-pre-banner--mobile {
  margin: 0;
}
.cassie-pre-banner-text--center {
  padding-left: 2rem;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.6;
  color: var(--cassie-preview-text-color);
}
.flex-wrap {
  flex-wrap: wrap;
}
.cassie-pre-banner-button-container--center {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 40%;
  margin: 0;
}

.cassie_preview_type_tabs_container {
  border-radius: 5px;
}
.cassie_preview_type_tab {
  font-size: 10px;
  color: white !important;
  text-transform: var(--cassie-tab-text-transform);
}

.cassie_preview_type_tab_active {
  background: var(--cassie-tab-active-preview);
  border-radius: 5px;
}
.cassie_preview_type_tab_active::before {
  opacity: 0.12;
}

.cassie_device_type_tab_active {
  color: white !important;
  background: var(--global-color-blue-darken-2);
}

.cassie_device_type_tab {
  margin: 0 10px;
  font-size: 10px;
  color: var(--global-color-blue-darken-2);
  border: 1px solid var(--global-color-blue-darken-2);
  border-radius: 5px;
}
p {
  margin-bottom: 0%;
}
.cassie_preview_container {
  width: 100%;
  height: 500px;
  box-shadow: inset 0 0 0 2000px rgba(179, 173, 176, 0.3);
}
.cassie_preview_container_background {
	background-color: rgba(0,0,0,.7);
}
.cassie_preview_container--tablet {
  width: 100%;
  max-width: 1024px;
}
.cassie_preview_container--mobile {
  width: 100%;
  max-width: 414px;
}

.cassie-manage-cookies--button {
  max-width: 150px;
  min-height: 44px;
  max-height: 50px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 12px;
  padding: 0 10px;
  margin: 10px;
}
</style>
<!-- banner styles -->
<style lang="scss">
	.cookie-modal {
		margin-right: 1rem;
		display: flex;
		flex-direction: column;
		flex-grow: 3;
		position: relative;
		&--mobile {
			flex-grow: 1;
			margin-bottom: 1rem;
		}
	}
	.details {
		margin-left: auto;
		flex-grow: 4;
		border-left: 3px solid white;
		padding-left: 1rem;
		width: 60%;
		position: relative;
		margin-right: auto;
		&--mobile {
			margin: 0;
			width: 100%;
			border: none;
			padding-left: 0;
		}
		&--desktop {
			padding-top: 1rem;
		}
		&--tablet {
			padding-top: 1rem;
		}
		.footer-extra-image {
			position: absolute;
			bottom: 0;
			right: 0;
			&--panels {
				left: 3;
				right: initial;
			}

			img {
				bottom: 0;
			}
		}
		> .details-header {
			margin-bottom: 1rem;
			font-size: 18px;
		}
	}
	.footer-extra-image ::v-deep img {
		max-height: 100%;
		max-width: 100%;
	}
	.cookie-list {
		margin-left: 0;
		padding-right: 0.5rem;
		border: 2px solid var(--cookie-modal-text-color);
		border-radius: 5px;
		&--mobile {
			margin-left: 0;
		}
		> .cookie {
			margin-top: 0.5rem;
			padding-bottom: 0.5rem;
			list-style: none;
			border-bottom: 2px solid var(--cookie-modal-text-color);
			margin-bottom: 5px;
			.cookie-name {
				font-weight: 500;
			}
			.cookie-header {
				display: flex;
				justify-content: space-between;
			}
			.cookie-description {
				font-size: 13px;
				margin-top: 0.5rem;
			}
		}
		> .cookie:last-child {
			border-bottom: none;
		}
	}
	.footer {
		width: 100%;
		font-size: 12px;
		line-height: 1.6;
		margin-top: auto;
		&-extra {
			margin-top: 1rem;
			justify-content: space-between;
			&--panels {
				position: absolute;
				bottom: 1rem;
				right: 1rem;
			}
			&-image {
				position: relative;
				width: 30%;
				a {
					position: absolute;
					top: 50%;
					left: 50%;
					width: 100%;
					margin: 0;
					-ms-transform: translate(-50%, -50%);
					transform: translate(-50%, -50%);
				}
			}
		}
	}
	.save-preferences {
		width: 10rem;
		max-height: 50px;
		min-height: 50px;
		padding: 0 10px;
		font-size: 12px;
		font-weight: 600;
		color: var(--cassie-submit-preferences-button-text-color);
		cursor: pointer;
		background-color: var(--cassie-submit-preferences-button-color);
		border-color: var(--cassie-submit-preferences-button-color);
		border-style: solid;
		border-width: 1px;
		border-radius: 5px;
		border-image: initial;
	}

	.cassie-cookie-modal--panels--desktop {
		.cookie-modal {
			width: 45%;
		}
	}
	.cassie-cookie-modal--panels--tablet {
		.cookie-modal {
			width: 45%;
		}
	}
	.cassie-cookie-modal {
		position: relative;
		z-index: 192;
		display: flex;
		flex-direction: column;
		flex-wrap: initial;
		width: 25rem;
		height: 100%;
		padding: 20px;
		overflow: hidden scroll;
		color: var(--cookie-modal-text-color) !important;
		background-color: var(--cookie-modal-background-color) !important;
		inset: 0;
		&--left {
			border-right: var(--cassie-submit-preferences-button-color);
			border-right-width: 1rem;
			border-right-style: solid;
			align-self: flex-start;
			&--mobile {
				width: 100%;
			}
		}
		&--right {
			align-self: flex-end;
			border-left: var(--cassie-submit-preferences-button-color);
			border-left-width: 1rem;
			border-left-style: solid;
			&--mobile {
				width: 100%;
			}
		}
		&--panels {
			width: 55rem;
			max-width: 100%;
			height: 500px;
			flex-direction: row;
			margin: auto;
			&--mobile {
				height: 100%;
				flex-direction: column;
			}
		}
		&--middle {
			width: 33rem;
			max-width: 100%;
			height: 500px;
			&--mobile {
				height: 100%;
				width: 100%;
			}

			margin: auto;
		}
	}
	.cassie-cookie-modal--accept-all {
		display: flex;
		justify-content: space-between;
		margin-bottom: 1rem;
	}
	.cassie-toggle-switch--checkbox {
		position: relative;
		right: 48px;
		bottom: 27px;
		z-index: 1;
		width: 90px;
		height: 24px;
		cursor: pointer;
		opacity: 0;
	}
	.cassie-cookie-modal--header--content {
		width: 100%;
		padding-top: 10px;
		margin: 0 0 10px;
		font-size: 12px;
		line-height: 1.6;
		overflow-wrap: break-word;
		border-color: var(--cookie-modal-secondary-color);
		border-top-style: solid;
		border-top-width: 2px;
	}

	.cassie-cookie--child--heading {
		width: 60%;
		margin: 0;
		font-size: 12px;
		line-height: 1.6;
	}
	.cassie_preview_container {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 680px;
		margin: auto;
		box-shadow: inset 0 0 0 2000px rgba(179, 173, 176, 0.3);
	}
	.cassie-toggle-switch--status {
		position: relative;
		top: -2px;
		right: 50px;
		font-size: 12px;
	}
	.cassie-cookie--child--description {
		width: 75%;
		margin: 5px 0;
		font-size: 12px;
		line-height: 1.6;
	}

	.cassie-cookie-modal--main {
		display: flex;
		flex-shrink: 0;
		align-self: flex-start;
		width: 100%;
		border-color: var(--cookie-modal-secondary-color);
		border-width: 2px 2px 0;
		border-top-style: solid;
		border-right-style: solid;
		border-bottom-style: initial;
		border-left-style: solid;
		border-image: initial;
		&--panels {
			border: none;
			display: block;
		}
	}

	.cassie-cookie-modal--cookies--container {
		width: 100%;
	}
	.cassie-modal-header {
		width: 50%;
		&--middle {
			margin: auto;
		}

	}
	.cassie-toggle-switch {
		position: relative;
		width: 50px;
		height: 24px;
		margin-left: 10px;
		cursor: pointer;
	}
	.tab {
		padding-bottom: 0.5rem;
		margin-top: 1rem;
		margin-bottom: 1.1rem;
		width: 50%;
		text-align: center;
		cursor: pointer;
		border-bottom-color: var(--cookie-modal-text-color);
		border-bottom-style: solid;
		border-bottom-width: 2px;
		&--active {
			border-bottom-color: var(--cookie-modal-active-tab);
			transition: 0.2s border-bottom-color ease-in-out;
		}
	}
	.cassie-cookie-modal--footer-image {
		width: 7rem;
	}
	.cassie_preview_container--tablet {
		width: 100%;
		max-width: 1024px;
	}
	.cassie-cookie-modal--header-image > img {
		max-height: 50%;
		max-width: 50%;
	}
	.cassie-cookie-modal--header-image {
		margin: 10px 0;
		&--middle {
			max-width: 50%;
		}
		&--panels {
			max-width: 50%;
		}
	}
	.cassie_preview_container--mobile {
		width: 100%;
		max-width: 414px;
	}

	.cassie--gpc--flag {
		color: var(--cassie-submit-preferences-button-text-color);
		background-color: var(--cassie-submit-preferences-button-color);
		font-size: 8px;
		border-radius: 10px;
		padding: 2px 5px;
		margin-bottom: 2px;
		margin-left: 5px;
		font-weight: bold;
	}

</style>
