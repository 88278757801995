export const COOKIES_DROPPED_ON_LOAD = {
	DROP_NONE: 1,
	DROP_STRICTLY_NECESSARY: 2,
	DROP_ALL: 3,
	DROP_ALL_EXC_GPC: 4
}

export const COOKIES_DROPPED_ON_LOAD_ITEMS = [
	{
		value: COOKIES_DROPPED_ON_LOAD.DROP_NONE,
		text: 'No Cookies Dropped'
	},
	{
		value: COOKIES_DROPPED_ON_LOAD.DROP_STRICTLY_NECESSARY,
		text: 'Drop Strictly Necessary Cookies Only'
	},
	{
		value: COOKIES_DROPPED_ON_LOAD.DROP_ALL,
		text: 'Drop All Cookies'
	},
	{
		value: COOKIES_DROPPED_ON_LOAD.DROP_ALL_EXC_GPC,
		text: 'Drop All Cookies Excluding GPC'
	}
]
// Global Enums
export const COLOUR_SCHEME_IDENTIFIERS = {
	DARK: 0,
	LIGHT: 1,
	CUSTOM: 2,
	NONE: 3
}

export const COLOUR_SCHEME_RADIOS = [
	{
		value: COLOUR_SCHEME_IDENTIFIERS.DARK,
		label: 'Dark'
	},
	{
		value: COLOUR_SCHEME_IDENTIFIERS.LIGHT,
		label: 'Light'
	},
	{
		value: COLOUR_SCHEME_IDENTIFIERS.CUSTOM,
		label: 'Custom'
	},
	{
		value: COLOUR_SCHEME_IDENTIFIERS.NONE,
		label: 'None'
	}
]

// Pre Banner Enums

export const PRE_BANNER_LOCATIONS = {
	TOP: 0,
	CENTRE: 1,
	BOTTOM: 2
}

export const PRE_BANNER_LOCATION_RADIOS = [
	{
		value: PRE_BANNER_LOCATIONS.BOTTOM,
		imageSrc: require('../../../../../assets/images/pre-banner-bottom.svg')
	},
	{
		value: PRE_BANNER_LOCATIONS.TOP,
		imageSrc: require('../../../../../assets/images/pre-banner-top.svg')
	},
	{
		value: PRE_BANNER_LOCATIONS.CENTRE,
		imageSrc: require('../../../../../assets/images/pre-banner-centre.svg')
	}
]

export const PRE_BANNER_CUSTOM_SCHEME_COLOURS = [
	{
		id: 'BackgroundColour',
		text: 'Background Colour',
		color: '#FFFFFF'
	},
	{
		id: 'TextColour',
		text: 'Text Colour',
		color: '#000000'
	},
	{
		id: 'AcceptAllButtonColour',
		text: 'Accept All Button Colour',
		color: '#FFFFFF'
	},
	{
		id: 'AcceptAllButtonTextColour',
		text: 'Accept All Button Text Colour',
		color: '#000000'
	},
	{
		id: 'RejectAllButtonColour',
		text: 'Reject All Button Colour',
		color: '#FFFFFF'
	},
	{
		id: 'RejectAllButtonTextColour',
		text: 'Reject All Button Text Colour',
		color: '#000000'
	},
	{
		id: 'CustomiseAllButtonColour',
		text: 'Customise Button Colour',
		color: '#FFFFFF'
	},
	{
		id: 'CustomiseAllButtonTextColour',
		text: 'Customise Button Text Colour',
		color: '#000000'
	},
	{
		id: 'SecondaryColour',
		text: 'Secondary Colour',
		color: '#000000'
	}
]

export const PRE_BANNER_DARK_COLOUR_SCHEME = {
	identifier: COLOUR_SCHEME_IDENTIFIERS.DARK,
	BackgroundColour: '#2e2e37',
	SecondaryColour: '#4f4f50',
	TextColour: '#FFFFFF',
	AcceptAllButtonColour: '#e31f26',
	AcceptAllButtonTextColour: '#FFFFFF',
	RejectAllButtonColour: '#e31f26',
	RejectAllButtonTextColour: '#FFFFFF',
	CustomiseAllButtonColour: '#e31f26',
	CustomiseAllButtonTextColour: '#FFFFFF'
}

export const PRE_BANNER_LIGHT_COLOUR_SCHEME = {
	identifier: COLOUR_SCHEME_IDENTIFIERS.LIGHT,
	BackgroundColour: '#FFFFFF',
	SecondaryColour: '#4f4f50',
	TextColour: '#000000',
	AcceptAllButtonColour: '#0679ab',
	AcceptAllButtonTextColour: '#000000',
	RejectAllButtonColour: '#0679ab',
	RejectAllButtonTextColour: '#000000',
	CustomiseAllButtonColour: '#0679ab',
	CustomiseAllButtonTextColour: '#000000'
}

export const PRE_BANNER_DEFAULT_CUSTOM_COLOUR_SCHEME = {
	identifier: COLOUR_SCHEME_IDENTIFIERS.CUSTOM,
	BackgroundColour: '#FFFFFF',
	SecondaryColour: '#000000',
	TextColour: '#000000',
	AcceptAllButtonColour: '#FFFFFF',
	AcceptAllButtonTextColour: '#000000',
	RejectAllButtonColour: '#FFFFFF',
	RejectAllButtonTextColour: '#000000',
	CustomiseAllButtonColour: '#FFFFFF',
	CustomiseAllButtonTextColour: '#000000'
}

// GPC Banner Enums
export const GPC_BANNER_CUSTOM_SCHEME_COLOURS = [
	{
		id: 'BackgroundColour',
		text: 'Background Colour',
		color: '#FFFFFF'
	},
	{
		id: 'TextColour',
		text: 'Text Colour',
		color: '#000000'
	},
	{
		id: 'AcceptAllButtonColour',
		text: 'Accept All Button Colour',
		color: '#FFFFFF'
	},
	{
		id: 'AcceptAllButtonTextColour',
		text: 'Accept All Button Text Colour',
		color: '#000000'
	},
	{
		id: 'AcceptAllExcGpcButtonColour',
		text: 'Accept All Exc GPC Button Colour',
		color: '#FFFFFF'
	},
	{
		id: 'AcceptAllExcGpcButtonTextColour',
		text: 'Accept All Exc GPC Button Text Colour',
		color: '#000000'
	},
	{
		id: 'BackButtonColour',
		text: 'Back Button Colour',
		color: '#FFFFFF'
	},
	{
		id: 'BackButtonTextColour',
		text: 'Back Button Text Colour',
		color: '#000000'
	},
	{
		id: 'SecondaryColour',
		text: 'Secondary Colour',
		color: '#000000'
	}
]

export const GPC_BANNER_DARK_COLOUR_SCHEME = {
	identifier: COLOUR_SCHEME_IDENTIFIERS.DARK,
	BackgroundColour: '#2e2e37',
	SecondaryColour: '#4f4f50',
	TextColour: '#FFFFFF',
	AcceptAllButtonColour: '#e31f26',
	AcceptAllButtonTextColour: '#FFFFFF',
	AcceptAllExcGpcButtonColour: '#e31f26',
	AcceptAllExcGpcButtonTextColour: '#FFFFFF',
	BackButtonColour: '#e31f26',
	BackButtonTextColour: '#FFFFFF'
}

export const GPC_BANNER_LIGHT_COLOUR_SCHEME = {
	identifier: COLOUR_SCHEME_IDENTIFIERS.LIGHT,
	BackgroundColour: '#FFFFFF',
	SecondaryColour: '#4f4f50',
	TextColour: '#000000',
	AcceptAllButtonColour: '#0679ab',
	AcceptAllButtonTextColour: '#000000',
	AcceptAllExcGpcButtonColour: '#0679ab',
	AcceptAllExcGpcButtonTextColour: '#000000',
	BackButtonColour: '#0679ab',
	BackButtonTextColour: '#000000'
}

export const GPC_BANNER_DEFAULT_CUSTOM_COLOUR_SCHEME = {
	identifier: COLOUR_SCHEME_IDENTIFIERS.CUSTOM,
	BackgroundColour: '#FFFFFF',
	SecondaryColour: '#000000',
	TextColour: '#000000',
	AcceptAllButtonColour: '#FFFFFF',
	AcceptAllButtonTextColour: '#000000',
	AcceptAllExcGpcButtonColour: '#FFFFFF',
	AcceptAllExcGpcButtonTextColour: '#000000',
	BackButtonColour: '#FFFFFF',
	BackButtonTextColour: '#000000'
}

// Banner Enums

export const BANNER_LOCATIONS = {
	MIDDLE: 0,
	RIGHT: 1,
	LEFT: 2,
	PANELS: 3
}

export const BANNER_LOCATION_RADIOS = [
	{
		value: BANNER_LOCATIONS.LEFT,
		imageSrc: require('../../../../../assets/images/banner-left.svg')
	},
	{
		value: BANNER_LOCATIONS.MIDDLE,
		imageSrc: require('../../../../../assets/images/banner-middle.svg')
	},
	{
		value: BANNER_LOCATIONS.PANELS,
		imageSrc: require('../../../../../assets/images/banner-panels.svg')
	},
	{
		value: BANNER_LOCATIONS.RIGHT,
		imageSrc: require('../../../../../assets/images/banner-right.svg')
	}
]

export const BANNER_DARK_COLOUR_SCHEME = {
	identifier: COLOUR_SCHEME_IDENTIFIERS.DARK,
	BackgroundColour: '#2e2e37',
	SecondaryColour: '#4f4f50',
	TextColour: '#FFFFFF',
	SubmitPreferencesButtonColour: '#e31f26',
	SubmitPreferencesButtonTextColour: '#FFFFFF',
	AcceptAllButtonColour: '#2e2e37',
	AcceptAllButtonTextColour: '#FFFFFF',
	RejectAllButtonColour: '#2e2e37',
	RejectAllButtonTextColour: '#FFFFFF',
	SliderBackgroundOn: '#e31f26',
	SliderBackgroundOff: '#4f4f50',
	SliderToggle: '#FFFFFF'
}

export const BANNER_LIGHT_COLOUR_SCHEME = {
	identifier: COLOUR_SCHEME_IDENTIFIERS.LIGHT,
	BackgroundColour: '#FFFFFF',
	SecondaryColour: '#4f4f50',
	TextColour: '#000000',
	SubmitPreferencesButtonColour: '#e31f26',
	SubmitPreferencesButtonTextColour: '#FFFFFF',
	AcceptAllButtonColour: '#2e2e37',
	AcceptAllButtonTextColour: '#FFFFFF',
	RejectAllButtonColour: '#FFFFFF',
	RejectAllButtonTextColour: '#2e2e37',
	SliderBackgroundOn: '#e31f26',
	SliderBackgroundOff: '#4f4f50',
	SliderToggle: '#FFFFFF'
}

export const BANNER_DEFAULT_CUSTOM_COLOUR_SCHEME = {
	identifier: COLOUR_SCHEME_IDENTIFIERS.CUSTOM,
	BackgroundColour: '#FFFFFF',
	SecondaryColour: '#000000',
	TextColour: '#000000',
	SubmitPreferencesButtonColour: '#FFFFFF',
	SubmitPreferencesButtonTextColour: '#000000',
	AcceptAllButtonColour: '#FFFFFF',
	AcceptAllButtonTextColour: '#000000',
	RejectAllButtonColour: '#FFFFFF',
	RejectAllButtonTextColour: '#000000',
	SliderBackgroundOn: '#000000',
	SliderBackgroundOff: '#000000',
	SliderToggle: '#FFFFFF'
}

export const BANNER_CUSTOM_SCHEME_COLOURS = [
	{
		id: 'BackgroundColour',
		text: 'Background Colour',
		color: BANNER_DEFAULT_CUSTOM_COLOUR_SCHEME.BackgroundColour
	},
	{
		id: 'TextColour',
		text: 'Text Colour',
		color: BANNER_DEFAULT_CUSTOM_COLOUR_SCHEME.TextColour
	},
	{
		id: 'SubmitPreferencesButtonColour',
		text: 'Submit Preferences Button Colour',
		color: BANNER_DEFAULT_CUSTOM_COLOUR_SCHEME.SubmitPreferencesButtonColour
	},
	{
		id: 'SubmitPreferencesButtonTextColour',
		text: 'Submit Preferences Text Colour',
		color: BANNER_DEFAULT_CUSTOM_COLOUR_SCHEME.SubmitPreferencesButtonTextColour
	},
	{
		id: 'AcceptAllButtonColour',
		text: 'Accept All Button Colour',
		color: BANNER_DEFAULT_CUSTOM_COLOUR_SCHEME.AcceptAllButtonColour
	},
	{
		id: 'AcceptAllButtonTextColour',
		text: 'Accept All Button Text Colour',
		color: BANNER_DEFAULT_CUSTOM_COLOUR_SCHEME.AcceptAllButtonTextColour
	},
	{
		id: 'RejectAllButtonColour',
		text: 'Reject All Button Colour',
		color: BANNER_DEFAULT_CUSTOM_COLOUR_SCHEME.RejectAllButtonColour
	},
	{
		id: 'RejectAllButtonTextColour',
		text: 'Reject All Button Text Colour',
		color: BANNER_DEFAULT_CUSTOM_COLOUR_SCHEME.RejectAllButtonTextColour
	},
	{
		id: 'SecondaryColour',
		text: 'Secondary Colour',
		color: BANNER_DEFAULT_CUSTOM_COLOUR_SCHEME.SecondaryColour
	},
	{
		id: 'SliderBackgroundOff',
		text: 'Slider Background Off',
		color: BANNER_DEFAULT_CUSTOM_COLOUR_SCHEME.SliderBackgroundOff
	},
	{
		id: 'SliderBackgroundOn',
		text: 'Slider Background On',
		color: BANNER_DEFAULT_CUSTOM_COLOUR_SCHEME.SliderBackgroundOn
	},
	{
		id: 'SliderToggle',
		text: 'Slider Toggle',
		color: BANNER_DEFAULT_CUSTOM_COLOUR_SCHEME.SliderToggle
	}
]
