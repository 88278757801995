<template>
	<div
		v-if="
			(activeTabUserConsent && hasConsentCookies.length > 0) ||
				(!activeTabUserConsent && hasLegitimateInterestCookies.length > 0)
		"
		class="cassie-cookie-modal-group"
	>
		<div
			class="cassie-cookie-modal--group-head-container"
			aria-expanded="false"
		>
			<div
				class="cassie-strictly-necessary--expand cassie-expand-cookies--container"
				tabindex="0"
				@click="expandCategory = !expandCategory"
			>
				<div class="d-flex">
					<span
						class="expander"
						:class="{ 'expander--disabled': !hasCookies }"
						:style="{ visibility: hasCookies ? '' : 'hidden' }"
					>
						<svg
							id="cassie_strictly_necessary_expand_icon"
							class="cassie-expand-cookies--icon cassie-expand-cookies--icon expand-icon"
							:class="{ 'expand-icon--open': expandCategory }"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 24 24"
							width="18px"
							height="18px"
						>
							<path
								d="M0 0h24v24H0z"
								fill="none"
							/>
							<path
								class="cassie-expand-cookies--icon"
								d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"
							/>
						</svg>
					</span>
					<h3 class="cassie-cookie-group--heading">
						{{
							cookieCategory.name
						}}
					</h3>
				</div>

				<!-- Cookie Category Accept/Reject Switch -->
				<BaseSwitch
					v-if="!cookieCategory.isStrictlyNecessary"
					:value="activeTabUserConsent ? false : true"
					:slider-background-off="bannerAppearanceTemplate.bannerColourSchemeJSON.SliderBackgroundOff"
					:slider-background-on="bannerAppearanceTemplate.bannerColourSchemeJSON.SliderBackgroundOn"
					:slider-color="bannerAppearanceTemplate.bannerColourSchemeJSON.SliderToggle"
				>
					<template v-if="bannerAppearanceTemplate.showToggleLabels">
						{{
							cookieCategory.isOptIn ? bannerTextTemplate.optInLabel : bannerTextTemplate.optOutLabel
						}}
					</template>
				</BaseSwitch>
			</div>
			<p
				id="cassie_strictly_necessary_description"
				v-dompurify-html="cookieCategory.description"
				class="cassie-strictly-necessary--description cassie-cookie-group--description"
			/>
			<!-- eslint-enable -->
		</div>
		<div
			v-show="expandCategory"
			class="cassie-strictly-necessary--children--container cassie-cookie-children--container"
		>
			<div
				v-for="cookie in cookieCategory.cookies"
				:key="cookie.id"
				class="cookie"
			>
				<template
					v-if="
						(cookie.cookieScriptType !== 'Legitimate Interest' && activeTabUserConsent) ||
							(cookie.cookieScriptType === 'Legitimate Interest' && !activeTabUserConsent)
					"
				>
					<div
						class="cookie-header d-flex space-between"
					>
						<h4
							class="cookie-header-text"
						>
							{{ cookie.name }}
							<span
								v-if="cookie.gpcEnabled && gpcEnabled"
								class="cassie--gpc--flag"
							>GPC</span>
						</h4>
						<!-- Cookie Script Accept/Reject Switch -->
						<BaseSwitch
							v-if="!cookieCategory.isStrictlyNecessary"
							v-model="cookie.isOptIn"
							:slider-background-off="bannerAppearanceTemplate.bannerColourSchemeJSON.SliderBackgroundOff"
							:slider-background-on="bannerAppearanceTemplate.bannerColourSchemeJSON.SliderBackgroundOn"
							:slider-color="bannerAppearanceTemplate.bannerColourSchemeJSON.SliderToggle"
						>
							<template v-if="bannerAppearanceTemplate.showToggleLabels">
								{{
									cookieCategory.isOptIn ? bannerTextTemplate.optInLabel : bannerTextTemplate.optOutLabel
								}}
							</template>
						</BaseSwitch>
					</div>
					<p
						v-dompurify-html="cookie.description"
						class="cookie-description"
					/>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseSwitch from './base-switch.vue'
export default {
	name: 'cookie-category',
	components: { BaseSwitch },
	props: {
		optInAll: {
			type: Boolean,
			required: true
		},
		activeTabUserConsent: {
			type: Boolean,
			required: true
		},
		cookieCategory: {
			type: Object,
			required: true
		},
		bannerAppearanceTemplate: {
			type: Object,
			required: true
		},
		bannerTextTemplate: {
			type: Object,
			required: true
		},
		gpcEnabled: {
			type: Boolean,
			default: false
		}
	},
	data () {
		return {
			expandCategory: false
		}
	},
	computed: {
		...mapGetters('consentBanners', ['globalGpcEnabled']),
		hasCookies () {
			return this.cookieCategory.cookies.length > 0
		},

		hasConsentCookies () {
			const cookies = this.cookieCategory.cookies.filter(cookie => {
				return cookie.cookieScriptType !== 'Legitimate Interest'
			})

			return cookies
		},
		hasLegitimateInterestCookies () {
			const cookies = this.cookieCategory.cookies.filter(cookie => {
				return cookie.cookieScriptType === 'Legitimate Interest'
			})

			return cookies
		},

		groupToggleStatus () {
			let groupOn = false

			if (!this.activeTabUserConsent) {
				groupOn = true
			}

			return groupOn
		}
	}
}
</script>

<style lang="scss" scoped>
.cassie-cookie-modal-group {
  display: flex;
  flex-wrap: wrap;
  border-color: var(--cookie-modal-secondary-color);
  border-bottom-style: solid;
  border-bottom-width: 2px;
}
.cassie-cookie-modal--group-head-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
}
.cassie-expand-cookies--container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0;
  cursor: pointer;
}
.expander {
  width: 1rem;
  &--disabled {
    pointer-events: none;
    cursor: none;
  }
  .expand-icon {
    fill: var(--cassie-submit-preferences-button-color);
    &--open {
      transform: scaleY(-1);
    }
  }
}
.cookie {
  width: 100%;
  padding-right: 0.4rem;
  padding-left: 0.4rem;
  margin-bottom: 10px;
  &-description {
    margin: 0 1rem;
    font-size: 12px;
    line-height: 1.6;
		width: 75%;
  }
  &-header {
    width: 100%;
    margin-bottom: 0.5rem;
		justify-content: space-between;
    &-text {
      margin: 0 1rem;
      font-size: 12px;
      font-weight: 700;
      line-height: 1.6;
      color: var(--cookie-modal-secondary-color);
    }
  }
}
.expand-text {
  font-size: 12px;
  margin-right: 0.2rem;
}
.cassie-cookie-group--heading {
  margin: 0 2px;
  font-size: 14px;
  line-height: 1.6;
  color: var(--cookie-modal-text-color);
}

.cassie-cookie-group--description {
  width: 75%;
  margin: 0 0 10px 20px;
  font-size: 12px;
  line-height: 1.6;
}

.cassie-cookie-children--container {
	padding: 10px;
  min-width: 100%;
	background-color: var(--cookie-modal-text-color);
	color: var(--cookie-modal-secondary-color);
}

.cassie--gpc--flag {
  color: var(--cassie-submit-preferences-button-text-color);
  background-color: var(--cassie-submit-preferences-button-color);
  font-size: 8px;
  border-radius: 10px;
  padding: 2px 5px;
  margin-bottom: 2px;
  margin-left: 5px;
  font-weight: bold;
}
</style>
